import React from 'react'
import styled from 'styled-components'
import richTextRenderer from 'utils/src/richTextRenderer'
import { above, color } from 'ui/src/styles/index'
import Seo from '../seo'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'

function PageNotFound({ locale }) {
  const { pageNotFoundMessage, pageNotFoundImage, seoPageNotFoundTitle, node_locale } =
    useWebsiteSettings(locale)
  const { description, file } = pageNotFoundImage
  return (
    <PageNotFoundWrapper>
      <Seo title={seoPageNotFoundTitle} lang={node_locale} />
      <ErrorImage src={file.url} alt={description} />
      <Content>{richTextRenderer(pageNotFoundMessage, node_locale)}</Content>
    </PageNotFoundWrapper>
  )
}

const PageNotFoundWrapper = styled.article`
  padding: 118.5px 34px 137.5px 19.5px;
  background-color: ${color.white};
  width: 100%;
  text-align: center;
  min-height: 420px;

  ${above.mobile`
		padding: 237px 68px 275px 39px;
	`}

  ${above.tablet`
		padding: 380px 0 237px 0;
	`}

	${above.tabletLarge`
		padding: 211px 0 162px 0;
	`}

	${above.desktop`
		padding: 181px 0 167px 0;
	`}
`

const ErrorImage = styled.img`
  background-color: ${color.white};
  width: 266.5px;
  height: 112px;

  ${above.mobile`
		height: 224px;
		width: 533px;
	`}

  ${above.tablet`
		margin: 0 0 47px 0;
		height: 259px;
		width: 615px;

  `}
  
  ${above.tabletLarge`
		margin: 0 0 42px 0;
		height: 261px;
		width: 617px;

  `}
  
  ${above.desktop`
		margin: 0 0 40px 0;
		height: 327px;
		width: 778px;

	`};
`

const Content = styled.div`
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color.costaRed};
  }
`

export default PageNotFound
