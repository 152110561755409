import { useStaticQuery, graphql } from 'gatsby'

const useLocaleList = () => {
  const { allContentfulPageAssembly } = useStaticQuery(graphql`
    {
      allContentfulPageAssembly {
        nodes {
          node_locale
        }
      }
    }
  `)
  const locales = new Set([])

  allContentfulPageAssembly.nodes.map(node => locales.add(node.node_locale))

  return Array.from(locales)
}

export default useLocaleList
