import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import richTextRenderer from 'utils/src/richTextRenderer'
import {
  above,
  below,
  color,
  headingTwo,
  headingThree,
  xsmallBodyText,
  baseBodyText,
} from 'ui/src/styles/index'

import LinkCta from '../../LinkCta/linkCta'
import ios from 'cw-frontend/src/images/apple-store.png'
import android from 'cw-frontend/src/images/google-store.jpg'
import getDeviceType from 'cw-frontend/src/helpers/getDeviceType'

function PromoGridAppButton({
  image,
  richText,
  node_locale,
  mobileImage,
  linkCTA,
  iosLink,
  iosAlt,
  androidLink,
  androidAlt,
  regionId,
  grid2x2,
}) {
  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  const getButton = () => {
    if (hasWindow) {
      switch (getDeviceType(window.navigator.userAgent)) {
        case 'android':
          return (
            <AndroidAppLink
              className="iconLink"
              href={androidLink}
              data-cy="google-play-link__link"
              grid2x2={grid2x2}
            >
              <AndroidImage src={android} alt={androidAlt} className="appIcons" grid2x2={grid2x2} />
            </AndroidAppLink>
          )
        case 'ios':
          return (
            <AppLink className="iconLink" href={iosLink} data-cy="ios-link__link" grid2x2={grid2x2}>
              <AppImage src={ios} alt={iosAlt} className="appIcons" grid2x2={grid2x2} />
            </AppLink>
          )
        case 'other':
          return (
            <Button
              link={linkCTA[0].link}
              customTitle={linkCTA[0].title}
              ctaStyle="button"
              alignment="center"
              grid2x2={grid2x2}
            />
          )
        default:
          return null
      }
    }
    return null
  }

  return (
    <Wrapper data-cy="app-button-grid" id={regionId} grid2x2={grid2x2}>
      {image.file.contentType.includes('svg') ? (
        <SvgWrapper grid2x2={grid2x2}>
          <SVGImage src={image.file.url} />
        </SvgWrapper>
      ) : (
        <Image
          data-cy="app-button-cta__image"
          image={image}
          mobileImage={mobileImage}
          alt={image.description}
          grid2x2={grid2x2}
        />
      )}
      <Content grid2x2={grid2x2}>{richTextRenderer(richText, node_locale)}</Content>
      {getButton()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${below.smallMobile`
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: 136px;'
        : `width: 320px;
    `};
  `}

  background-color: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto 40px;
`

const AppImage = styled.img`
  ${below.smallMobile`
      ${({ grid2x2 }) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: 136px;'
          : `width: 164px;
      `};
    `}
  height: 48px;
  width: 142px;

  ${above.tablet`
    height: 52px;
    width: 153px;
    padding: 0;
    margin: 0;
  `};
`

const AppLink = styled.a`
  ${below.smallMobile`
      ${({ grid2x2 }) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: 136px;'
          : `width: 164px;
      `};
    `}
  margin: 0 auto;
  height: 48px;
  width: 142px;

  ${above.tablet`
    height: 52px;
    width: 153px;
  `}
`

const AndroidAppLink = styled(AppLink)`
  ${below.smallMobile`
      ${({ grid2x2 }) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: 136px;'
          : `width: auto;
      `};
    `}
  width: auto;

  ${above.tablet`
    width: auto;
  `}
`

const AndroidImage = styled(AppImage)`
  ${below.smallMobile`
      ${({ grid2x2 }) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: 136px;'
          : `width: 164px;
      `};
    `}
  width: auto;

  ${above.tablet`
    width: auto;
  `}
`

const Button = styled(LinkCta)`
  a {
    height: 48px;
    width: 164px;
    margin-bottom: 40px;

    ${above.tablet`
      width: 187px;
      height: 56px;
    `}

    &:after {
      border-left: 6px solid ${color.white};
    }
  }
`

const Image = styled.div.attrs(props => ({
  alt: props.image.description,
}))`
  ${below.smallMobile`
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: 136px;'
        : `width: 320px;
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'height: 136px;'
        : `height: 242px;
    `};
  `}
  background-image: ${({ mobileImage }) => `url(${mobileImage?.file.url})`};
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto; min-width: 144px;'
      : `width: 320px;
    `};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'height: auto; min-height: 144px;'
      : `height: 242px;
    `};

  ${above.tablet`
    background-image: ${({ image }) => `url(${image?.file.url})`};
    height: 144px;
    width: 144px;
  `}

  ${above.tabletLarge`
    height: 198px;
    width: 198px;
  `}
`

const SVGImage = styled.img`
  margin: auto;
  height: 72px;

  ${above.tablet`
  height: 100px;
`}
`

const SvgWrapper = styled.div`
  display: flex;
  ${below.smallMobile`
      ${({ grid2x2 }) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: auto;'
          : `width: 320px;
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'height: 136px;'
        : `height: 242px;
    `};
  `}

  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
    `};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'height: 190px;'
      : `height: 242px;
    `};

  ${above.tablet`
  height: 180px;
  width: 240px;
`}

  ${above.tabletLarge`
  width: 320px;
  height: 241px;
`}

${above.desktop`
  height: 248px;
  width: 330px;
`}
`

const Content = styled.div`
  ${below.smallMobile`
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: auto;'
        : `width: 304px;
    `};
  `}
  height: auto;
  text-align: center;
  color: ${color.greyDarker};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
  `};

  ${above.tablet`
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
  `}

  ${above.desktop`
    width: 330px;
  `}

  h4 {
    color: ${color.costaRed};
    margin: 21px 0 10px;
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${headingThree};`
        : `font-size: ${headingTwo};
    `};
  }

  p {
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${xsmallBodyText};`
        : `font-size: ${baseBodyText};
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'margin: 0 8px 16px;'
        : `margin: 0 34px 36px;
    `};
    ${above.tablet`
      font-size: ${baseBodyText};
      margin: 0 20px 35px;
    `}
  }
`

export default PromoGridAppButton
