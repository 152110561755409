import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'
import { above, color } from 'ui/src/styles'

import PromoVideo from 'ui/src/Promo/PromoVideo'
import LinkCta from '../LinkCta/linkCta'
import { getBackgroundColor } from 'ui/src/Promo/utils'
import type { MaybeEmpty } from 'cw-frontend/src/types/utils'
import type * as Contentful from 'cw-frontend/src/types/contentful/old'

type IProps = MaybeEmpty<{
  standardStyleBgColour: string
  videoUrl: string
  image: Contentful.ContentfulMedia
  imageAlignment: string
  seasonalSVG: Contentful.ContentfulMedia
  richText: RichTextBody
  linkCTA: Contentful.ContentfulLinkCta[]
  displaySeasonalSVG: Contentful.ContentfulMedia
  regionId: string
  fallbackImage: Contentful.ContentfulMedia
}>

function Standard({
  standardStyleBgColour = '',
  videoUrl,
  image = {},
  imageAlignment = '',
  seasonalSVG,
  richText,
  linkCTA,
  displaySeasonalSVG,
  regionId = '',
  fallbackImage,
}: IProps) {
  const mediaType = image?.file?.contentType ?? ''
  const backUpImage = fallbackImage?.file?.url

  // Set background colour for Standard Promo.
  const bgColour = getBackgroundColor(standardStyleBgColour)

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <Block imageAlignment={imageAlignment} id={regionId ?? undefined}>
      <Content styleColor={bgColour}>
        {displaySeasonalSVG && (
          <SeasonalSVG src={seasonalSVG?.file?.url} imageAlignment={imageAlignment} alt="" />
        )}
        <RichText>{richTextRenderer(richText)}</RichText>
        {linkCTA && (
          <LinkCtaList>
            {linkCTA.map(link => (
              <li key={link.title}>
                <LinkCta
                  link={link.link}
                  customTitle={link.title}
                  ctaStyle={link.style || 'button'}
                  alignment={link.alignment || 'left'}
                />
              </li>
            ))}
          </LinkCtaList>
        )}
      </Content>
      {videoUrl && mediaType.includes('image') && (
        <PromoVideo videoSrc={videoUrl} thumbnailSrc={image?.file?.url ?? ''} />
      )}
      {!videoUrl && mediaType.includes('image') && image?.gatsbyImageData && (
        <ImageWrapper>
          <GatsbyImage
            style={{ height: '100%', minHeight: '300px', width: '100%' }}
            image={image.gatsbyImageData}
            alt={image.description ?? ''}
          />
        </ImageWrapper>
      )}
      {!videoUrl && mediaType.includes('video') && (
        <VideoContainer>
          {hasWindow && (
            <ReactPlayer
              url={image?.file?.url}
              config={{
                file: {
                  attributes: {
                    poster: `${backUpImage}`,
                  },
                },
              }}
              playsinline
              playing
              loop
              muted
              wrapper={Video}
              height="100%"
              width="100%"
            />
          )}
        </VideoContainer>
      )}
    </Block>
  )
}

interface BlockProps {
  imageAlignment: IProps['imageAlignment']
}

const Block = styled.article<BlockProps>`
  margin: 80px auto;
  padding: 0;
  ul {
    margin: 0px;
    padding: 0px;
  }

  ${above.tablet<BlockProps>`
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${p => p.imageAlignment}
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `}
`

interface ContentProps {
  styleColor: string
}

const Content = styled.div<ContentProps>`
  background-color: white;
  background-color: ${p => p.styleColor};
  position: relative;
  width: 100%;
  list-style: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 48px 10%;
  min-height: 300px;

  ${above.mobile`
  padding: 96px 10%;
`}

  ${above.tablet`
    width: 50%;
    padding: 80px 5%;
    min-height: 512px;
  `}

  ${above.desktop`
  padding: 80px 6.5%;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${color.costaRed};
    margin: 0 0 20px 0;
    width: 100%;

    ${above.tablet`
      margin-top: 0;
    `}
  }

  p {
    margin-top: 0;
    width: 100%;

    ${above.desktop`
    padding: 0px 30px;
    `};
  }

  a {
    margin-bottom: 0;
  }
`

interface SeasonalSVGProps {
  imageAlignment?: string | null
}

const SeasonalSVG = styled.img<SeasonalSVGProps>`
  left: ${p => (p.imageAlignment === 'initial' ? '10%' : 'auto')};
  right: ${p => (p.imageAlignment === 'row-reverse' ? '10%' : 'auto')};
  z-index: -1;
  position: absolute;
  top: -64px;
  max-width: 100px;
  max-height: 100px;
`

const LinkCtaList = styled.ul`
  li + li {
    margin-top: 18px;
  }
`

const RichText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 16px;
  text-align: center;

  ${above.tablet`
    margin: 0 auto 18px;
  `}
`

const ImageWrapper = styled.div`
  width: auto;
  margin: 0;
  min-height: 300px;

  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`

const VideoContainer = styled.div`
  display: flex;
  min-height: 73px;
  position: relative;

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `};
`

const Video = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    min-width: 100%;
    min-height: 100%;
    width: 100% !important;
    height: auto !important;

    ${above.tablet`
      position: absolute;
      width: auto !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
  }
`

export default Standard
