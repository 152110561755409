import React from 'react'
import FMCGRegistrationForm from 'ui/src/StaticComponent/components/FMCGRegistrationForm/FMCGRegistrationForm'
import type { FMCGFormConfig } from 'ui/src/StaticComponent/components/FMCGRegistrationForm/types'
import { useFMCGLoserPromo } from './FMCGLoserPromoQuery'
import Promo from '../../Promo/promo'

function FMCGRegistrationFormWrapper({
  anchor,
  locale,
  config,
}: {
  anchor?: string
  locale: string | undefined
  config: FMCGFormConfig
}) {
  const contentfulLoserPromo = useFMCGLoserPromo(locale)

  function renderLoserPromo() {
    if (!contentfulLoserPromo) return null

    return <Promo {...contentfulLoserPromo} />
  }

  return (
    <FMCGRegistrationForm
      anchor={anchor}
      locale={locale}
      loserPromo={renderLoserPromo()}
      config={config}
    />
  )
}

export default FMCGRegistrationFormWrapper
