import React from 'react'
import styled, { css } from 'styled-components'

import { Logo } from 'ui/src/Logo'
import { AltLogo } from './AltLogo'
import isRtl from 'utils/src/rtl'
import { above } from 'ui/src/styles'
import localeFormatter from 'utils/src/helpers/localeFormatter'
import type * as Contentful from 'cw-frontend/src/types/contentful/old'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

export interface IProps {
  logo: Contentful.ContentfulMedia | undefined
  alternativeLogo: Contentful.ContentfulLink | undefined
  locale: string | undefined
  onClick: () => void
  onKeyDown: () => void
  headerIcons: string
  ariaLabel: string
}

export function NavLogo(props: IProps) {
  const { ariaLabel, alternativeLogo, logo, locale, headerIcons, onClick, onKeyDown } = props

  if (alternativeLogo) {
    return (
      <Container locale={locale} headerIcons={headerIcons}>
        <AltLogo {...props} alternativeLogo={alternativeLogo} />
      </Container>
    )
  }

  return (
    <Container locale={locale} headerIcons={headerIcons}>
      <Logo
        link={{
          to: locale !== defaultLocale ? `/${localeFormatter(locale)}/` : '/',
          onClick,
          onKeyDown,
          'aria-label': ariaLabel,
        }}
        image={logo?.gatsbyImageData}
        description={logo?.description ?? ''}
      />
    </Container>
  )
}

interface ContainerProps {
  locale?: string
  headerIcons: string
}

const Container = styled.div<ContainerProps>`
  display: inline-block;

  ${({ locale, headerIcons }) => css`
    ${headerIcons !== 'true' &&
    css`
      margin: 0 auto;

      ${isRtl(locale ?? '') ? 'padding-left: 92px;' : 'padding-right: 92px;'}

      ${above.tabletLarge`
        ${isRtl(locale ?? '') ? 'padding-left: 0;' : 'padding-right: 0;'}
      `}
    `}

    ${above.tabletLarge`
      margin: ${isRtl(locale ?? '') ? '3px 40px 0 24px' : '3px 24px 0 40px'};
    `}
  `}
`
