import React from 'react'
import Layout from '../layout/layout'
import PageNotFound from '../components/404/404'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import useWebsiteSettings from '../hooks/useWebsiteSettings'

const extractLocaleFromUrl = location => {
  return location.pathname.match(/\/[a-z]{2}\//)
    ? location.pathname.match(/\/[a-z]{2}\//)[0].replace(/\//g, '')
    : undefined
}

function Page404({ location }) {
  const { seoPageNotFoundTitle, node_locale } = useWebsiteSettings(extractLocaleFromUrl(location))
  return (
    <Layout locale={extractLocaleFromUrl(location)} location={location} noBackground>
      <AnalyticsDataLayer location={location} locale={node_locale} title={seoPageNotFoundTitle} />
      <PageNotFound locale={extractLocaleFromUrl(location)} />
    </Layout>
  )
}

export default Page404
