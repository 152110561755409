import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player'
import richTextRenderer from 'utils/src/richTextRenderer'
import { color, above } from 'ui/src/styles/index'
import WaveSeparator from 'ui/src/WaveSeparator'
import PromoVideo from 'ui/src/Promo/PromoVideo'
import LinkCta from '../LinkCta/linkCta'

function Campaign({
  style = '',
  imageAlignment = '',
  campaignStyleBgColour = '',
  campaignStyleTextColour = '',
  seasonalSVG,
  richText = {},
  linkCTA = [],
  videoUrl,
  image = {},
  displaySeasonalSVG,
  regionId = '',
  nodeLocale,
  waveBorder,
  fallbackImage,
}) {
  const mediaType = image.file.contentType
  const textColour =
    campaignStyleBgColour && campaignStyleTextColour !== 'standard' ? color.white : color.greyDarker
  const ctaColour = textColour && textColour === '#ffffff' ? 'white' : 'costaRed'

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <Wrapper id={regionId}>
      {displaySeasonalSVG && (
        <SeasonalSVG src={seasonalSVG.file.url} imageAlignment={imageAlignment} alt="" />
      )}
      <Block imageAlignment={imageAlignment} waveBorder={waveBorder}>
        <Content campaignStyleBgColour={campaignStyleBgColour} textColour={textColour}>
          {waveBorder && (
            <WaveBorderWrapper imageAlignment={imageAlignment}>
              <StyledWaveBorder color={campaignStyleBgColour} />
            </WaveBorderWrapper>
          )}
          <RichText promoStyle={style}>{richTextRenderer(richText, nodeLocale)}</RichText>
          {linkCTA && (
            <LinkCtaList>
              {linkCTA.map(link => (
                <li key={link.title}>
                  <LinkCta
                    link={link.link}
                    customTitle={link.title}
                    ctaStyle={link.style || 'button'}
                    alignment={link.alignment || 'left'}
                    customColor={ctaColour}
                    customLinkColor={ctaColour}
                  />
                </li>
              ))}
            </LinkCtaList>
          )}
        </Content>
        {videoUrl && mediaType.includes('image') && (
          <PromoVideo videoSrc={videoUrl} thumbnailSrc={image.file.url} />
        )}
        {!videoUrl && mediaType.includes('image') && image && (
          <ImageWrapper>
            <GatsbyImage
              style={{ height: '100%', minHeight: '300px', width: '100%' }}
              image={image.gatsbyImageData}
              alt={image.description}
            />
          </ImageWrapper>
        )}
        {!videoUrl && mediaType.includes('video') && (
          <VideoContainer>
            {hasWindow && (
              <ReactPlayer
                url={image.file.url}
                config={{
                  file: {
                    attributes: {
                      poster: `${fallbackImage?.file?.url}`,
                    },
                  },
                }}
                playsinline
                playing
                loop
                muted
                wrapper={Video}
                height="100%"
                width="100%"
              />
            )}
          </VideoContainer>
        )}
      </Block>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  position: relative;
`

const WaveBorderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 14px;
  z-index: 1;

  ${above.tablet`
      top: initial;
      right: ${({ imageAlignment }) => (imageAlignment === 'initial' ? '14px' : 'initial')};
      left: ${({ imageAlignment }) => (imageAlignment === 'row-reverse' ? '14px' : 'initial')};
      transform: ${({ imageAlignment }) =>
        imageAlignment === 'row-reverse' ? 'rotate(180deg)' : 'rotate(0deg)'};
  `}
`

const StyledWaveBorder = styled(WaveSeparator)`
  color: ${p => p.color || 'white'};
  position: absolute;
  z-index: 1;
  transform: rotate(90deg);

  ${above.tablet`
    transform: rotate(0deg);
  `}
`

const Block = styled.div`
  overflow: hidden;
  margin: 80px auto;
  padding: 0;
  display: flex;

  // image on top when the wave is enabled
  flex-direction: ${({ waveBorder }) => (waveBorder ? 'column-reverse' : 'column')};

  ul {
    margin: 0px;
    padding: 0px;
  }

  ${above.tablet`
    max-width: 100%;
    flex-wrap: nowrap;
    flex-direction: ${p => p.imageAlignment}
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `}
`

const Content = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  background-color: ${p => p.campaignStyleBgColour};
  color: ${p => p.textColour};
  list-style: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 48px 10%;
  min-height: 300px;

  ${above.mobile`
    padding: 96px 10%;
  `}

  ${above.tablet`
  width: 50%;
  padding: 80px 5%;
  min-height: 512px;
  `}

  ${above.desktop`
  padding: 80px 6.5%;
  `}

  h1,h2,h3,h4,h5,h6 {
    color: ${p => (p.textColour === color.white ? color.white : color.costaRed)};
    margin: 0 0 20px 0;
    width: 100%;
  }

  p {
    margin-top: 0;
    color: ${p => p.textColour};
    width: 100%;

    ${above.desktop`
    padding: 0px 30px;
    `};
  }

  a {
    margin-bottom: 0;
  }
`

const SeasonalSVG = styled.img`
  left: ${({ imageAlignment }) => (imageAlignment === 'initial' ? '10%' : 'auto')};
  right: ${({ imageAlignment }) => (imageAlignment === 'row-reverse' ? '10%' : 'auto')};
  z-index: -1;
  position: absolute;
  top: -64px;
  max-width: 100px;
  max-height: 100px;
`

const LinkCtaList = styled.ul`
  li + li {
    margin-top: 18px;
  }
`

const RichText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 16px;
  text-align: center;
  position: relative;
  z-index: 2;

  ${above.tablet`
    margin: 0 auto 18px;
  `}
`

const ImageWrapper = styled.div`
  width: auto;
  margin: 0;
  min-height: 300px;

  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`

const VideoContainer = styled.div`
  display: flex;
  min-height: 73px;
  position: relative;

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `};
`

const Video = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    min-width: 100%;
    min-height: 100%;
    width: 100% !important;
    height: auto !important;
    display: block;

    ${above.tablet`
      position: absolute;
      width: auto !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
  }
`

export default Campaign
