import React from 'react'
import styled from 'styled-components'
import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'
import isRtl from 'utils/src/rtl'
import { color, costaText, above, costaTextBold, xsmallBodyText } from 'ui/src/styles'
import type { MaybeEmpty } from 'cw-frontend/src/types/utils'

type IProps = MaybeEmpty<{
  content: RichTextBody
  node_locale: string
  footnote: string
  backgroundColour: string
}>

function RichText(props: IProps) {
  const { content, node_locale, footnote, backgroundColour } = props

  return (
    <Container footnote={footnote}>
      <Content locale={node_locale ?? ''} footnote={footnote} bgColour={backgroundColour}>
        {richTextRenderer(content)}
      </Content>
    </Container>
  )
}

interface ContainerProps {
  footnote: IProps['footnote']
}

const Container = styled.article<ContainerProps>`
  margin: ${({ footnote }) => (footnote === 'true' ? 'none' : '0px 2.5%')};
  padding: ${({ footnote }) => (footnote === 'true' ? '0 32px;' : '0')};

  ${above.tablet<ContainerProps>`
    margin: ${({ footnote }) => (footnote === 'true' ? 'none' : '0px 6%')};
    padding: ${({ footnote }) => (footnote === 'true' ? '0 104px;' : '0')};
  `}

  ${above.tabletLarge<ContainerProps>`
    margin: ${({ footnote }) => (footnote === 'true' ? 'none' : '0px 9%')};
    padding: ${({ footnote }) => (footnote === 'true' ? '0 144px;' : '0')};
  `}
  
  ${above.desktopLarge<ContainerProps>`
    padding: ${({ footnote }) => (footnote === 'true' ? '0 15%;' : '0')};
    margin: ${({ footnote }) => (footnote === 'true' ? 'none' : '0px 4%')};
    max-width: 1274px;
  `}
`

interface ContentProps {
  footnote: IProps['footnote']
  locale: string
  bgColour: IProps['backgroundColour']
}

const Content = styled.div<ContentProps>`
  background-color: ${getContentBackgroundColour};
  padding: 0;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5;
  padding: 20px 3%;
  max-width: 608px;
  margin: 0px auto;

  ${above.tablet<ContentProps>`
    font-size: 18px;
    padding: ${({ footnote }) => (footnote === 'true' ? '0' : '56px 5%')};
    max-width: 674px;
  `}

  ${above.tabletLarge<ContentProps>`
    max-width: 824px;
    padding: ${({ footnote }) => (footnote === 'true' ? '0' : '40px 9%')};
  `} 

  ${above.desktop<ContentProps>`
    max-width: 1392px;
    padding: ${({ footnote }) => (footnote === 'true' ? '0' : '40px 5%')};
  `};

  ${above.desktopLarge`
    max-width: 1392px;
  `};

  p {
    ${({ footnote }) => (footnote === 'true' ? xsmallBodyText : costaText)};
    font-style: ${({ footnote }) => (footnote === 'true' ? 'italic' : 'normal')};
    margin: 16px 0 32px;
    padding: 0;

    ${above.tablet`
      margin: 18px 0 32px;
    `}
  }

  strong {
    ${costaTextBold}
  }

  a {
    ${costaText}
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    word-wrap: break-word;

    &:hover {
      color: ${color.lightRed};
    }
  }

  ul,
  ol {
    p {
      padding: 0;
      margin: 0;
      display: inline;
    }
  }
  ul {
    padding-left: 40px;
    li {
      list-style-type: inherit;
      list-style-position: inside;
    }
  }

  ol {
    padding-left: 40px;
    li {
      list-style-type: inherit;
      list-style-position: inside;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 40px;
    color: ${color.costaRed};

    ${above.tablet`
      margin: 0 0 29px;
    `}
  }

  .inline-right,
  .inline-left {
    display: block;
    width: 100%;
    padding-bottom: 27px;

    ${above.tabletLarge`
      width: 48%;
      box-sizing: content-box;
    `}

    ${above.desktop`
      width: 389px;
    `}
  }

  .inline-${({ locale }) => (isRtl(locale) ? 'left' : 'right')} {
    ${above.tablet`
      float: right;
    `}
    ${above.tabletLarge`
      padding-left: 40px;
    `}
  }

  .inline-${({ locale }) => (isRtl(locale) ? 'right' : 'left')} {
    ${above.tablet`
      float: left;
    `}
    ${above.tabletLarge`
      padding-right: 40px;
    `}
  }
`

function getContentBackgroundColour({ bgColour, footnote }: ContentProps) {
  if (bgColour) return bgColour

  return footnote === 'true' ? 'none' : color.white
}

export default RichText
