import React from 'react'
import styled from 'styled-components'
import { above, costaTextBold } from 'ui/src/styles/index'
import ContentfulLink from '../ContentfulLink/contentfulLink'

function SecondaryNav({ links, location, locale }) {
  return (
    <Wrapper>
      {links.map(navItem => (
        <StyledLink
          key={navItem.title}
          showActive={
            location.pathname.endsWith(encodeURIComponent(navItem?.internalLink?.slug))
              ? 'inline'
              : 'none'
          }
        >
          <ContentfulLink link={navItem} locale={locale} />
        </StyledLink>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  height: 60px;
  ${above.tablet`
    height: 80px;
    `}
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  background-color: #b51144;
  ::before,
  ::after {
    content: '';
    margin: auto;
    min-width: 13px;

    ${above.tablet`
      min-width: 10px;
      `}
  }
`

const StyledLink = styled.li`
  ${costaTextBold}
  font-size: 16px;
  text-decoration: none;
  flex: 0 0 auto;
  color: white;
  line-height: 30px;
  padding: 12px 16px 15px;
  ${above.tablet`
    font-size: 20px;
    line-height: 40px;
    padding: 15px 28px 18px;
  `}
  position: relative;
  ::after {
    display: ${props => props.showActive};
    content: '';
    position: absolute;
    left: 15px;
    bottom: 14px;
    width: calc(100% - 30px); // to make border-bottom 2px longer
    border-bottom: 2px solid white;
    ${above.tablet`
        left: 27px;
        bottom: 29px;
        width: calc(100% - 54px); 
      `}
  }
`

export default SecondaryNav
