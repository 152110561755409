import { graphql, useStaticQuery } from 'gatsby'

export function useFMCGLoserPromo(locale?: string) {
  const { allContentfulPromo } = useStaticQuery(graphql`
    query OldFMCGLoserPromoQuery {
      allContentfulPromo(filter: { cmsLabel: { eq: "FMCG Registration Form - Loser Promo" } }) {
        nodes {
          ...PromoFragment
        }
      }
    }
  `)

  return allContentfulPromo.nodes.find((element: any) => element.node_locale.includes(locale))
}
