import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, type PageProps } from 'gatsby'
import styled from 'styled-components'
import ContentfulBlockStoreLocatorMap from 'store-locator'
import { LinkCta as ContentfulNormalisedLink } from 'ui/src/link/'
import ContentfulAccordion from 'ui/src/Accordion/accordion'
import ContentfulNormalisedBreakoutFact from 'ui/src/BreakoutFact/breakoutFact'

import headerLanguageTags from 'utils/src/headerLanguageTags'
import isRtl from 'utils/src/rtl'
import NewsletterModal from 'ui/src/NewsletterModal/NewsletterModal'
import ContentfulStaticComponent from '../components/StaticComponent/staticComponent'
import Layout from '../layout/layout'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import ContentfulBenefitIcons from '../components/BenefitIcons/benefitIcons'
import ContentfulLinkCta from '../components/LinkCta/linkCta'
import ContentfulPromo from '../components/Promo/promo'
import ContentfulPromoAppButtons from '../components/PromoAppButtons/promoAppButtons'
import ContentfulRichText from '../components/RichText/richText'
import ContentfulCookiePolicyTable from '../components/CookiePolicyTable/cookiePolicyTable'
import ContentfulBusinessContactForm from '../components/BusinessContactForm/businessContactForm'
import ContentfulPromoGridCollection from '../components/PromoGridCollection/promoGridCollection'
import ContentfulImage from '../components/FullWidthImage/fullWidthImage'
import useWebsiteSettings from '../hooks/useWebsiteSettings'
import openGraphImage from '../images/RAF.jpg'
import type { ContentfulPage } from 'cw-frontend/src/types/contentful/old/ContentfulPage'

const contentBlockList = {
  ContentfulAccordion,
  ContentfulBenefitIcons,
  ContentfulNormalisedBreakoutFact,
  ContentfulLinkCta,
  ContentfulPromo,
  ContentfulRichText,
  ContentfulCookiePolicyTable,
  ContentfulPromoAppButtons,
  ContentfulBusinessContactForm,
  ContentfulPromoGridCollection,
  ContentfulImage,
  ContentfulStaticComponent,
  ContentfulBlockStoreLocatorMap,
  ContentfulNormalisedLink,
}

interface PageContext {
  currentLocale?: string
}

interface PageData {
  contentfulPage: ContentfulPage
}

export default function Page({ data, location, pageContext }: PageProps<PageData, PageContext>) {
  const { currentLocale } = pageContext
  const page = data.contentfulPage
  const { slug } = page.pageassembly?.[0] ?? {}
  const websiteSettings = useWebsiteSettings(currentLocale)
  const siteURL = process.env.GATSBY_SITE_URL ?? ''

  const noindex = websiteSettings.noindex === 'noindex' || page.noindex === 'noindex'

  const ogImgURL = siteURL.replace(/\/$/, '') + openGraphImage
  const hasStoreLocator =
    page.contentBlocks?.some(e => e.__typename === 'ContentfulBlockStoreLocatorMap') === true

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <PageWrapper customBackground={page.customBackground || null}>
      {/* @ts-expect-error Issue with children prop */}
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={page.title} />
        <meta property="og:description" content={page.description} />
        <meta property="og:image" content={ogImgURL} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={page.title} />
        <meta property="twitter:description" content={page.description} />
        <meta property="twitter:image" content={ogImgURL} />
        <html lang={currentLocale} dir={isRtl(String(currentLocale)) ? 'rtl' : undefined} />

        {slug === '/' &&
          headerLanguageTags?.map(({ url, locale }) => (
            <link rel="alternate" href={url} hrefLang={locale} key={locale} />
          ))}
        {slug === 'costa-club' ? (
          <>
            <meta property="og:url" content={`${siteURL.replace(/\/$/, '')}/costa-club`} />
            <meta property="twitter:url" content={`${siteURL.replace(/\/$/, '')}/costa-club`} />
          </>
        ) : (
          <meta name="robots" content={noindex ? 'noindex' : 'all'} />
        )}
      </Helmet>

      {hasWindow && currentLocale && window.OneTrust
        ? window.OneTrust.changeLanguage(currentLocale.slice(0, 2))
        : null}

      {process.env.GATSBY_BUDDY_PIPELINE !== 'uk' && (
        <AnalyticsDataLayer title={page.title} locale={currentLocale} location={location} />
      )}

      <Layout
        hero={page.hero}
        secondaryNavigation={page.secondaryNavigation}
        location={location}
        locale={currentLocale}
        noBackground={hasStoreLocator}
        isStoreLocator={hasStoreLocator}
        alternativeLogo={page.alternativeLogo}
      >
        {page.contentBlocks &&
          page.contentBlocks.map(({ __typename: componentType, ...component }, index) => {
            const Component = getComponentByType(componentType)

            return Component ? (
              <Component key={String(component.id) + index} {...component} />
            ) : null
          })}

        {page.newsletterPopup === 'Yes' && <NewsletterModal locale={currentLocale} />}
      </Layout>
    </PageWrapper>
  )
}

function isComponentType(
  componentType: string | null | undefined
): componentType is keyof typeof contentBlockList {
  return componentType ? componentType in contentBlockList : false
}

function getComponentByType(componentType: string | null | undefined) {
  if (isComponentType(componentType)) {
    return contentBlockList[componentType]
  }

  return null
}

export const query = graphql`
  query PageQueryOld($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...ContentfulPageOldFragment
    }
  }
`

interface PageWrapperProps {
  customBackground: ContentfulPage['customBackground'] | null
}

const PageWrapper = styled.div<PageWrapperProps>`
  background-image: ${p => (p.customBackground ? `url(${p.customBackground.file.url})` : 'none')};
  background-color: ${p => (p.customBackground ? '#ffffff' : 'initial')};
  background-size: 320px 320px;
  background-repeat: repeat;
  position: relative;
  z-index: 0;
`
