import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, type IGatsbyImageData } from 'gatsby-plugin-image'
import ReactPlayer from 'react-player'

import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'
import { above, color } from 'ui/src/styles/index'
import getDeviceType from 'cw-frontend/src/helpers/getDeviceType'

type AppButton = Partial<{
  title: string
  platform: string
  appLinkUrl: string
  image: {
    file: { url: string }
  }
}>

type IProps = Partial<{
  imageAlignment: string
  mobileTextAlignment: string
  content: React.ReactNode
  richText: RichTextBody
  standardStyleBgColour: string
  campaignStyleBgColour: string | null
  headingTextColour: string
  appButtons: AppButton[]
  regionId: string
  image: {
    file: { url: string; contentType: string }
    description: string
    gatsbyImageData: IGatsbyImageData
  }
  slim: boolean
}>

function AppButtonPromo({
  imageAlignment,
  mobileTextAlignment = 'center',
  richText,
  content,
  standardStyleBgColour,
  campaignStyleBgColour = '',
  headingTextColour = '',
  appButtons,
  regionId,
  image,
  slim = false,
}: IProps) {
  const mediaType = image?.file?.contentType ?? ''
  const hasMedia = Boolean(mediaType)

  const campaignBgcolour = campaignStyleBgColour
  const standardBgcolour =
    standardStyleBgColour && standardStyleBgColour === 'Cloudless Blue'
      ? color.rebrandBlue
      : standardStyleBgColour === 'Nightfall Lilac'
      ? color.secondaryCore
      : standardStyleBgColour === 'Sunset Coral'
      ? color.tertiaryCore
      : standardStyleBgColour === 'Grey'
      ? color.offWhite
      : standardStyleBgColour === 'Costa Red'
      ? color.costaRed
      : color.white
  const align = imageAlignment === 'left' ? 'row-reverse' : 'initial'
  const headingColour =
    headingTextColour && headingTextColour === 'White' ? color.white : color.costaRed

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <Block imageAlignment={align} id={regionId}>
      <Content
        styleColor={campaignBgcolour ?? standardBgcolour}
        headingTextColour={headingColour}
        hasMedia={hasMedia}
        slim={slim}
      >
        <RichText mobileTextAlignment={mobileTextAlignment}>
          {content || richTextRenderer(richText)}
        </RichText>
        {hasWindow && appButtons && (
          <List mobileTextAlignment={mobileTextAlignment}>
            {appButtons.map(button => {
              if (
                getDeviceType(window.navigator.userAgent) === button.platform ||
                getDeviceType(window.navigator.userAgent) === 'other'
              ) {
                return (
                  <ListItem key={button.title}>
                    <Link href={button.appLinkUrl} target="_blank">
                      <ButtonImage src={button.image?.file.url} alt={button.title} />
                    </Link>
                  </ListItem>
                )
              }
              return null
            })}
          </List>
        )}
      </Content>
      {mediaType.includes('image') && image && (
        <ImageWrapper>
          <GatsbyImage
            style={{ height: '100%' }}
            image={image.gatsbyImageData}
            alt={image.description}
          />
        </ImageWrapper>
      )}
      {mediaType.includes('video') && (
        <VideoContainer>
          {hasWindow && (
            <ReactPlayer
              url={image?.file.url}
              playsinline
              playing
              loop
              muted
              wrapper={Video}
              height="100%"
              width="100%"
            />
          )}
        </VideoContainer>
      )}
    </Block>
  )
}

interface ListProps {
  mobileTextAlignment: string
}

const List = styled.ul<ListProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ mobileTextAlignment }) => mobileTextAlignment};

  ${above.tablet`
    justify-content: center;
  `}
`

const Link = styled.a`
  display: inline-block;
  height: 100%;
  width: 100%;
`

const ListItem = styled.li`
  height: 40px;

  & + li {
    padding-left: 8px;
  }

  ${above.desktop`
    padding: 0 16px;
  `}
`

interface BlockProps {
  imageAlignment: string
}

const Block = styled.article<BlockProps>`
  margin: 80px auto;
  padding: 0;
  ul {
    margin: 0px;
    padding: 0px;
  }

  ${above.tablet`
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${({ imageAlignment }: BlockProps) => imageAlignment};
  `}

  ${above.desktopLarge`
    max-width: 1392px;
  `}
`

const ButtonImage = styled.img`
  object-fit: contain;
  height: 100%;
`

const ImageWrapper = styled.div`
  width: auto;
  margin: 0;
  min-height: 300px;

  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `}
`
const VideoContainer = styled.div`
  display: flex;
  min-height: 73px;
  position: relative;

  ${above.tablet`
    width: 50%;
    height: auto;
    min-height: 512px;
  `};
`

const Video = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    min-width: 100%;
    min-height: 100%;
    width: 100% !important;
    height: auto !important;

    ${above.tablet`
      position: absolute;
      width: auto !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
  }
`

interface RichTextProps {
  mobileTextAlignment: string
}

const RichText = styled.div<RichTextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto 16px;
  text-align: ${({ mobileTextAlignment }) => mobileTextAlignment};

  ${above.tablet`
    margin: 0 auto 18px;
    text-align: center;
  `}
`

interface ContentProps {
  styleColor: string
  headingTextColour: string
  hasMedia: boolean
  slim: boolean
}

const Content = styled.div<ContentProps>`
  background-color: white;
  background-color: ${({ styleColor }) => styleColor};
  position: relative;
  width: 100%;
  color: ${color.greyDarker};
  list-style: none;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 48px 10%;
  min-height: 300px;

  ${above.mobile`
    padding: 96px 10%;
  `}

  ${({ hasMedia, slim }) => above.tablet`
    width: ${hasMedia ? '50%' : '100%'};
    padding: 80px 5%;
    ${!slim && 'min-height: 512px;'}
  `}

  ${above.desktop`
    padding: 80px 6.5%;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${p => p.headingTextColour};
    margin: 0 0 20px 0;
    width: 100%;

    ${above.tablet`
      margin-top: 0;
    `}
  }

  p {
    margin-top: 0;
    color: ${color.greyDarker};
    width: 100%;

    ${above.desktop`
      padding: 0px 30px;
    `};
  }

  a {
    margin-bottom: 0;
  }
`

export default AppButtonPromo
