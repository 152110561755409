import React from 'react'
import styled from 'styled-components'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'

function SocialMediaIcons() {
  const { socialMediaLinks } = useWebsiteSettings()
  return (
    <List>
      {socialMediaLinks.map(({ id, iconAltText, url, icon }) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={id}>
            <Link href={url} target="_blank" rel="noreferrer noopener">
              <Icon src={icon.file.url} alt={iconAltText} />
            </Link>
          </li>
        )
      })}
    </List>
  )
}

const List = styled.ul`
  display: flex;
  gap: 25px;
`

const Link = styled.a`
  &:focus {
    padding: 10px 0;
  }
`

const Icon = styled.img`
  height: 26px;
`

export default SocialMediaIcons
