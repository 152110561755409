const isProd = process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production'

const loadOneTrust =
  (process.env.GATSBY_ONETRUST_PROD === 'true' && isProd) ||
  (process.env.GATSBY_ONETRUST_DEV === 'true' && !isProd)

export default function hotjar(h, o, t, j, a, r) {
  h.hj =
    h.hj ||
    function () {
      ;(h.hj.q = h.hj.q || []).push(arguments)
    }
  h._hjSettings = { hjid: 2519238, hjsv: 6 }
  a = o.getElementsByTagName('head')[0]
  r = o.createElement('script')
  r.className = 'optanon-category-C0002'
  r.type = loadOneTrust ? 'text/plain' : 'text/javascript'
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  a.appendChild(r)
}
