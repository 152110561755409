import React from 'react'
import Banner from 'ui/src/Banner'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'

interface Props {
  locale: string
  enabled: boolean
}

/**
 * Wrapper for the Banner component that allows use of the useWebsiteSettings hook
 */
function BannerWrapper({ locale, enabled }: Props): React.ReactElement {
  const websiteSettings = useWebsiteSettings(locale)

  return (
    enabled &&
    websiteSettings.bannerMessages?.length && (
      <Banner
        messages={websiteSettings.bannerMessages}
        bgBrandColour={websiteSettings.bannerBgColour}
      />
    )
  )
}

export default BannerWrapper
