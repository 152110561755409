import React from 'react'
import styled from 'styled-components'

import { above, below, color } from 'ui/src/styles/index'

import PromoGridNoButton from './PromoGridNoButton/promoGridNoButton'
import PromoGridPrimaryButton from './PromoGridPrimaryButton/promoGridPrimaryButton'
import PromoGridAppButton from './PromoGridAppButton/promoGridAppButton'
import LinkCTA, { type IProps as LinkCTAProps } from '../LinkCta/linkCta'
import type { ContentfulMedia } from 'cw-frontend/src/types/contentful/old/ContentfulMedia'
import type { RichTextBody } from 'utils/src/richTextRenderer'
import { ContentfulLinkCta } from 'cw-frontend/src/types/contentful/old/ContentfulLinkCta'
import { computeMaxGridColumns } from 'cw-frontend/src/utils/promos'

type PropItem = Partial<{
  id: string
  __typename: string
  node_locale: string
  image: ContentfulMedia
  richText: RichTextBody
  backgroundColour: string
  brandStyleBgColour: string
  headingTextColour: string
  ctaColour: string
  textColour: string
  mobileImage: ContentfulMedia
  linkCTA: ContentfulLinkCta[]
  androidLink: string
  androidAlt: string
  iosLink: string
  iosAlt: string
  regionId: string
  hideLinkCta: boolean | null
}>

export type IProps = Partial<{
  heading: string
  headingAnchor: string
  text: string
  promos: PropItem[]
  grid2x2: string
  callToAction: LinkCTAProps
}>

function PromoGridCollection({
  heading,
  headingAnchor,
  text,
  promos,
  grid2x2,
  callToAction,
}: IProps) {
  return (
    <CollectionWrapper>
      <div>
        {heading && (
          <Heading id={headingAnchor} text={text}>
            {heading}
          </Heading>
        )}
        {text && <Text>{text}</Text>}
      </div>
      <GridWrapper data-cy="grid-promo__wrapper">
        {!!promos && (
          <PromosWrapper count={computeMaxGridColumns(promos.length)} grid2x2={grid2x2}>
            {promos.map(
              ({
                image,
                __typename,
                node_locale,
                richText,
                backgroundColour,
                brandStyleBgColour,
                headingTextColour,
                ctaColour,
                textColour,
                id,
                mobileImage,
                linkCTA,
                androidLink,
                androidAlt,
                iosLink,
                iosAlt,
                regionId,
                ...promoProps
              }) => {
                switch (__typename) {
                  case 'ContentfulPromoGridNoButton':
                    return (
                      <PromoGridNoButton
                        key={id}
                        image={image}
                        node_locale={node_locale}
                        richText={richText}
                        backgroundColour={backgroundColour}
                        brandStyleBgColour={brandStyleBgColour}
                        headingTextColour={headingTextColour}
                        textColour={textColour}
                        mobileImage={mobileImage}
                        grid2x2={grid2x2}
                      />
                    )
                  case 'ContentfulPromoGridAppButton':
                    return (
                      <PromoGridAppButton
                        key={id}
                        image={image}
                        node_locale={node_locale}
                        richText={richText}
                        linkCTA={linkCTA}
                        mobileImage={mobileImage}
                        androidLink={androidLink}
                        androidAlt={androidAlt}
                        iosLink={iosLink}
                        iosAlt={iosAlt}
                        regionId={regionId}
                        grid2x2={grid2x2}
                      />
                    )
                  case 'ContentfulPromoGridPrimaryButton': {
                    const { hideLinkCta } = promoProps

                    return (
                      <PromoGridPrimaryButton
                        key={id}
                        image={image}
                        node_locale={node_locale ?? ''}
                        richText={richText}
                        backgroundColour={backgroundColour}
                        brandStyleBgColour={brandStyleBgColour}
                        headingTextColour={headingTextColour}
                        textColour={textColour}
                        ctaColour={ctaColour}
                        mobileImage={mobileImage}
                        linkCTA={linkCTA?.[0]}
                        hideLinkCta={hideLinkCta}
                        grid2x2={grid2x2}
                      />
                    )
                  }
                  default:
                    return null
                }
              }
            )}
          </PromosWrapper>
        )}
      </GridWrapper>
      {callToAction && (
        <LinkWrapper>
          <LinkCTA link={callToAction.link} alignment="center" />
        </LinkWrapper>
      )}
    </CollectionWrapper>
  )
}

interface HeadingProps {
  text?: string
}

const Heading = styled.h3<HeadingProps>`
  width: 252px;
  margin-left: auto;
  margin-right: auto;
  color: ${color.costaRed};
  text-align: center;
  ${({ text }) => (text ? 'margin-bottom: 8px;' : 'margin-bottom: 56px;')}

  ${above.tablet`
     width: 550px;
	`}
  ${above.tablet`
     width: 656px;
	`}

  ${above.desktop`
     width: 100%;
	`}
`

const Text = styled.p`
  display: block;
  width: 252px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 56px;

  ${above.tablet`
     width: 550px;
	`}
  ${above.tablet`
     width: 656px;
	`}

  ${above.desktop`
     width: 100%;
	`}
`

interface PromosWrapperProps {
  grid2x2?: string
  count: number
}

const PromosWrapper = styled.div<PromosWrapperProps>`
  ${below.smallMobile`
      grid-column-gap: 16px;
      padding: 0 8px 60px;
  `}
  display: grid;
  overflow: hidden;
  justify-items: center;
  padding: 0 16px 60px;
  gap: 16px;

  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'grid-template-columns: 1fr 1fr'
      : `grid-template-columns: 1fr;
  `};

  ${above.tablet`
    ${({ count }: PromosWrapperProps) =>
      count === 4
        ? 'grid-template-columns: 1fr 1fr'
        : `grid-template-columns: repeat(${count}, 1fr)`};
    padding: 0 16px 80px;
    gap: 24px;
	`}

  ${above.desktop`
    ${({ count }: PromosWrapperProps) => `grid-template-columns: repeat(${count}, minmax(0,1fr))`};
    gap: 32px;
	`}
`

interface GridWrapperProps {
  grid2x2?: string
}

const GridWrapper = styled.div<GridWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'flex-direction: row'
      : `flex-direction: column
  `};

  ${above.mobile`
    margin: 0 auto;
    flex-direction: column;
	`}

  ${above.tablet`
    flex-direction: row;
	`}

  ${above.desktop`
    flex-direction: row;
	`}
`

const LinkWrapper = styled.div`
  margin: 0 auto 80px;
`

const CollectionWrapper = styled.article`
  display: flex;
  flex-direction: column;
`

export default PromoGridCollection
