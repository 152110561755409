import 'cw-frontend/src/styles/css/fontface.css'
import React from 'react'
import BannerWrapper from './src/components/BannerWrapper'

const onRouteUpdate = ({ location }) => {
  const viewName = location.pathname.substr(1, location.pathname.length - 2) || 'home'
  if (
    typeof adobe !== 'undefined' &&
    // eslint-disable-next-line no-undef
    adobe.target &&
    // eslint-disable-next-line no-undef
    typeof adobe.target.triggerView === 'function'
  ) {
    // eslint-disable-next-line no-undef
    adobe.target.triggerView(viewName)
  }
}

const wrapPageElement = page => {
  return (
    <>
      <BannerWrapper
        locale={page.props.pageContext.currentLocale}
        enabled={page.props.data?.contentfulPage?.banner === 'Enabled'}
      />
      {page.element}
    </>
  )
}

export { onRouteUpdate, wrapPageElement }
