import React from 'react'
import styled from 'styled-components'

import richTextRenderer from 'utils/src/richTextRenderer'
import {
  above,
  below,
  color,
  headingTwo,
  headingThree,
  xsmallBodyText,
  baseBodyText,
  brandColorToHex,
} from 'ui/src/styles/index'

function PromoGridNoButton({
  image,
  richText,
  node_locale,
  backgroundColour = '',
  brandStyleBgColour,
  headingTextColour,
  textColour,
  mobileImage,
  grid2x2,
}) {
  const brandBgColour = brandColorToHex(brandStyleBgColour)

  const bodyTextColour = textColour && textColour === 'White' ? color.white : color.greyDarker
  const titleTextColour =
    headingTextColour && headingTextColour === 'White' ? color.white : color.costaRed
  return (
    <Wrapper
      data-cy="no-cta__grid-promo"
      backgroundColour={backgroundColour ?? brandBgColour}
      grid2x2={grid2x2}
    >
      {image &&
        (image.file.contentType.includes('svg') ? (
          <SvgWrapper grid2x2={grid2x2}>
            <SVGImage src={image.file.url} />
          </SvgWrapper>
        ) : (
          <Image
            data-cy="no-cta__image"
            image={image}
            mobileImage={mobileImage}
            alt={image.description}
            grid2x2={grid2x2}
          />
        ))}
      <Content headingTextColour={titleTextColour} textColour={bodyTextColour} grid2x2={grid2x2}>
        {richTextRenderer(richText, node_locale)}
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${below.smallMobile`
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: 136px;'
        : `width: 304px;
    `};
  `}
  background-color: ${p => p.backgroundColour};
  margin: 0 auto;
  justify-content: center;
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: 100%;'
      : `width: 320px;
  `};

  ${above.tablet`
    width: 100%;
  `}
`

const Image = styled.div.attrs(props => ({
  alt: props.image.description,
}))`
  ${below.smallMobile`
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: auto;'
        : `width: 304px;
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'height: 136px;'
        : `height: 242px;
    `};
  `}
  background-image: ${({ mobileImage }) => `url(${mobileImage?.file.url})`};
  background-size: cover;
  background-position: center;
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
    `};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'height: 190px;'
      : `height: 242px;
  `};

  ${above.tablet`
    background-image: ${({ image }) => `url(${image?.file.url})`};
    height: 180px;
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
    height: 241px;
  `}

  ${above.desktop`
    height: 248px;
    width: 330px;
  `}
`

const SVGImage = styled.img`
  margin: auto;
  height: 72px;

  ${above.tablet`
  height: 100px;
`}
`

const SvgWrapper = styled.div`
  display: flex;
  ${below.smallMobile`
      ${({ grid2x2 }) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: auto;'
          : `width: 304px;
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'height: 136px;'
        : `height: 242px;
    `};
  `}

  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
    `};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'height: 190px;'
      : `height: 242px;
    `};

  ${above.tablet`
  height: 180px;
  width: 240px;
`}

  ${above.tabletLarge`
  width: 320px;
  height: 241px;
`}

${above.desktop`
  height: 248px;
  width: 330px;
`}
`

const Content = styled.div`
  ${below.smallMobile`
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: auto;'
        : `width: 304px;
    `};
  `}
  height: auto;
  text-align: center;
  color: ${p => p.textColour};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
  `};

  ${above.tablet`
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
  `}

  ${above.desktop`
    width: 330px;
  `}

  h1, h2, h3, h4, h5, h6 {
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${headingThree};`
        : `font-size: ${headingTwo};
    `};
    color: ${p => p.headingTextColour};
  }

  h4 {
    margin: 21px 0 10px;
  }

  p {
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${xsmallBodyText};`
        : `font-size: ${baseBodyText};
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'margin: 0 8px 16px;'
        : `margin: 0 34px 36px;
    `};

    ${above.tablet`
      font-size: ${baseBodyText};
      margin: 0 20px 35px;
    `}
  }
`

export default PromoGridNoButton
