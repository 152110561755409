import React from 'react'
import styled from 'styled-components'

import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'
import {
  above,
  below,
  color,
  headingTwo,
  headingThree,
  xsmallBodyText,
  baseBodyText,
  brandColorToHex,
} from 'ui/src/styles/index'
import LinkCta from '../../LinkCta/linkCta'
import ContentfulLink, {
  type IProps as ContentfulLinkProps,
} from '../../ContentfulLink/contentfulLink'
import type { ContentfulMedia } from 'cw-frontend/src/types/contentful/old/ContentfulMedia'

export interface IProps {
  image?: ContentfulMedia
  richText?: RichTextBody
  node_locale: string
  backgroundColour?: string
  brandStyleBgColour?: string
  headingTextColour?: string
  textColour?: string
  ctaColour?: string
  mobileImage?: ContentfulMedia
  linkCTA?: Partial<{
    title: string
    link: ContentfulLinkProps['link'] & { node_locale?: string | null }
    style: string
  }>
  hideLinkCta?: boolean | null
  grid2x2?: string
}

function PromoGridPrimaryButton({
  image,
  richText,
  backgroundColour = '',
  brandStyleBgColour,
  headingTextColour,
  textColour,
  ctaColour = '',
  mobileImage,
  linkCTA,
  hideLinkCta,
  grid2x2,
}: IProps) {
  const brandBgColour = brandColorToHex(brandStyleBgColour)

  const bodyTextColour = textColour && textColour === 'White' ? color.white : color.greyDarker
  const titleTextColour =
    headingTextColour && headingTextColour === 'White' ? color.white : color.costaRed
  const imageLink = { ...linkCTA }

  if (imageLink.link) imageLink.link.title = ''

  return (
    <Wrapper backgroundColour={backgroundColour ?? brandBgColour} grid2x2={grid2x2}>
      <ContentfulLink link={imageLink?.link} locale={linkCTA?.link?.node_locale}>
        {image &&
          ('file' in image && image.file?.contentType?.includes('svg') ? (
            <SvgWrapper grid2x2={grid2x2}>
              <SVGImage src={image.file.url} />
            </SvgWrapper>
          ) : (
            <Image image={image} mobileImage={mobileImage} grid2x2={grid2x2} />
          ))}
      </ContentfulLink>
      <FlexGrowWrapper>
        <Content headingTextColour={titleTextColour} textColour={bodyTextColour} grid2x2={grid2x2}>
          {richTextRenderer(richText)}
        </Content>
        {linkCTA && hideLinkCta !== true && (
          <LinkCta
            link={linkCTA.link}
            customTitle={linkCTA.title}
            ctaStyle={linkCTA.style || 'button'}
            alignment="center"
            customColor={ctaColour}
            grid2x2={grid2x2}
          />
        )}
      </FlexGrowWrapper>
    </Wrapper>
  )
}

const FlexGrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`

interface WrapperProps {
  grid2x2?: string
  backgroundColour: string
}

const Wrapper = styled.div<WrapperProps>`
  ${below.smallMobile`
    ${({ grid2x2 }: WrapperProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: 136px;'
        : `width: 304px;
    `};
  `}

  background-color: ${p => p.backgroundColour};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

type ImageProps = Partial<{
  grid2x2: string
  image: ContentfulMedia
  mobileImage: ContentfulMedia
}>

const Image = styled.div.attrs<ImageProps>(props => ({
  alt: props.image?.description,
}))`
  ${below.smallMobile`
    ${({ grid2x2 }: ImageProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: auto;'
        : `width: 304px;
    `};
    ${({ grid2x2 }: ImageProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'height: 136px;'
        : `height: 242px;
    `};
  `}

  background-image: ${({ mobileImage }: ImageProps) => `url(${mobileImage?.file?.url})`};
  background-size: cover;
  background-position: center;
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
    `};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'height: 190px;'
      : `height: 242px;
    `};

  ${above.tablet`
    background-image: ${({ image }: ImageProps) => `url(${image?.file?.url})`};
    height: 180px;
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
    height: 241px;
  `}

  ${above.desktop`
    height: 248px;
    width: 330px;
  `}
`

const SVGImage = styled.img`
  margin: auto;
  height: 72px;

  ${above.tablet`
  height: 100px;
`}
`

interface SvgWrapperProps {
  grid2x2?: string
}

const SvgWrapper = styled.div<SvgWrapperProps>`
  display: flex;

  ${below.smallMobile`
      ${({ grid2x2 }: SvgWrapperProps) =>
        grid2x2 && grid2x2 === '2x2'
          ? 'width: auto;'
          : `width: 304px;
    `};
    ${({ grid2x2 }: SvgWrapperProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'height: 136px;'
        : `height: 242px;
    `};
  `}

  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
    `};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'height: 190px;'
      : `height: 242px;
    `};

  ${above.tablet`
  height: 180px;
  width: 240px;
`}

  ${above.tabletLarge`
  width: 320px;
  height: 241px;
`}

${above.desktop`
  height: 248px;
  width: 330px;
`}
`

interface ContentProps {
  grid2x2?: string
  textColour?: string
  headingTextColour?: string
}

const Content = styled.div<ContentProps>`
  ${below.smallMobile`
    ${({ grid2x2 }: ContentProps) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'width: auto;'
        : `width: 304px;
    `};
  `}
  height: auto;
  text-align: center;
  color: ${p => p.textColour};
  ${({ grid2x2 }) =>
    grid2x2 && grid2x2 === '2x2'
      ? 'width: auto;'
      : `width: 320px;
    `};

  ${above.tablet`
    width: 240px;
  `}

  ${above.tabletLarge`
    width: 320px;
  `}

  ${above.desktop`
    width: 330px;
  `}

  h1, h2, h3, h4, h5, h6 {
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${headingThree};`
        : `font-size: ${headingTwo};
    `};
    color: ${p => p.headingTextColour};
  }

  h4 {
    margin: 21px 0 10px;
  }

  p {
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? `font-size: ${xsmallBodyText};`
        : `font-size: ${baseBodyText};
    `};
    ${({ grid2x2 }) =>
      grid2x2 && grid2x2 === '2x2'
        ? 'margin: 0 8px 16px;'
        : `margin: 0 34px 36px;
    `};
    ${above.tablet`
      font-size: ${baseBodyText};
      margin: 0 20px 35px;
    `}
  }
`

export default PromoGridPrimaryButton
