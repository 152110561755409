import React from 'react'
import styled from 'styled-components'
import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'
import { color, above } from 'ui/src/styles/index'
import LinkCta, { IProps as LinkCtaProps } from '../../LinkCta/linkCta'
import { ContentfulMedia } from 'cw-frontend/src/types/contentful/old/ContentfulMedia'

export interface IProps {
  content: Partial<{
    mobileBackground: ContentfulMedia
    tabletBackground: ContentfulMedia
    background: ContentfulMedia
    heroContent: RichTextBody
    linkCTA: Partial<{
      title: string
      link: LinkCtaProps['link']
      style: string
      alignment: string
    }>[]
  }>
}

export default function HeroWithTextBox({
  content: { mobileBackground, tabletBackground, background, heroContent, linkCTA },
}: IProps) {
  const tabletImage = tabletBackground ? tabletBackground.file?.url : background?.file?.url
  const mobileImage = mobileBackground ? mobileBackground.file?.url : background?.file?.url
  const image = background?.file?.url

  const hideInMobile = mobileBackground ? false : true

  return (
    <div>
      <Hero
        hideInMobile={hideInMobile}
        tabletImage={tabletImage}
        mobileImage={mobileImage}
        image={image}
      >
        <Gradient hideInMobile={hideInMobile} />
        <Wrapper hideInMobile={hideInMobile} />
      </Hero>
      <Content>
        {richTextRenderer(heroContent ? heroContent : '')}
        {linkCTA && (
          <ul>
            {linkCTA.map(link => (
              <li key={link.title}>
                <LinkCta
                  link={link.link}
                  customTitle={link.title}
                  ctaStyle={link.style || 'button'}
                  alignment={link.alignment || 'center'}
                />
              </li>
            ))}
          </ul>
        )}
      </Content>
    </div>
  )
}

interface HeroProps {
  hideInMobile: boolean
  mobileImage?: string
  tabletImage?: string
  megaHero?: boolean
  image?: string
}

const Hero = styled.div<HeroProps>`
  width: 100%;
  height: ${p => (p.hideInMobile ? '80px' : '448px')};
  background-size: cover;
  background-position: center;
  background-color: ${p => (p.hideInMobile ? `${color.costaRed}` : 'transparent')};
  background-image: ${p => (p.hideInMobile ? 'none' : `url(${p.mobileImage})`)};

  ${above.tablet`
    background-image: url(${(p: HeroProps) => p.tabletImage});
    height: ${(p: HeroProps) => (p.megaHero ? 800 : 384)}px;
  `}

  ${above.tabletLarge`
    background-image: url(${(p: HeroProps) => p.image})
    height: ${(p: HeroProps) => (p.megaHero ? 584 : 376)}px;
  `}

  ${above.desktop`
    height: ${(p: HeroProps) => (p.megaHero ? 680 : 464)}px;
  `}
`

interface GradientProps {
  hideInMobile: boolean
}

const Gradient = styled.div<GradientProps>`
  height: 66px;
  width: 100%;
  position: absolute;
  background: ${p =>
    p.hideInMobile ? 'none' : 'linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7))'};

  ${above.tablet`
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    height: 128px;
  `}

  ${above.tabletLarge`
    height: 192px;
  `}

  ${above.tablet`
    height: 300px;
  `}
`

interface WrapperProps {
  hideInMobile: boolean
}

const Wrapper = styled.div<WrapperProps>`
  display: ${p => (p.hideInMobile ? 'none' : 'flex')};
  margin: 0 auto;
  padding: 108px 20px 40px 20px;
  max-width: 1600px;

  ${above.tablet`
    display: flex;
    padding: 60px 0 0 0;
  `}

  ${above.desktop`
    padding: 150px 0 0 0;
  `}
`

const Content = styled.div`
  width: 95%;
  margin: 0 auto 50px;
  margin-top: -248px;
  padding: 48px 8% 9px;
  background: ${color.offWhite};
  border-radius: 3px;
  text-align: center;
  overflow: hidden;
  position: relative;

  ${above.tablet`
    width: 64%;
    margin-top: -210px;
    padding: 56px 8% 31px;
  `}

  ${above.tabletLarge`
  `}

  ${above.desktop`
    width: 684px;
    padding: 56px 56px 31px;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${color.costaRed};
    margin: 0 -4% 32px;

    ${above.tablet`
      margin-bottom: 40px;
    `}
  }

  p {
    margin-bottom: 32px;

    ${above.tablet`
      margin-bottom: 40px;
    `}
  }
`
