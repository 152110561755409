import React, { useState, useEffect } from 'react'
import cookie from 'cw-frontend/src/helpers/cookie'
import ConsentBanner from './ConsentBanner'

function ConsentBannerWrapper({ setAllowTracking, locale }) {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    setShowBanner(!cookie.hasChosenCookieSettings())

    if (cookie.areCookiesAllowed()) {
      setAllowTracking(true)
      window[`ga-disable-${process.env.GATSBY_GA_UA}`] = false
    } else {
      window[`ga-disable-${process.env.GATSBY_GA_UA}`] = true
      window._satellite = {
        track: () => {},
        pageBottom: () => {},
      }
    }
  }, [setAllowTracking, showBanner])

  return showBanner && <ConsentBanner setShowBanner={setShowBanner} locale={locale} />
}

export default ConsentBannerWrapper
