import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { useStoreLocatorMap } from '@cw-monorepo/contentful/src/hooks/useStoreLocatorMap'
import styled from 'styled-components'
import { above, costaTextBold, color } from 'ui/src/styles/index'
import { AccountOptions } from 'cw-frontend/src/helpers/loginHelper'

import avatarIcon from 'cw-frontend/src/assets/icons/avatar.svg'
import pinIcon from 'cw-frontend/src/assets/icons/location-pin.svg'
import type { Microcopy } from 'utils/src/microcopy'

// TODO remove hardcoded

interface IconsBarProps {
  locale?: string
  microcopy: Microcopy
}

export default function IconsBar(props: IconsBarProps) {
  const { locale, microcopy } = props
  const [isDropdownOpen, setIsDropDownOpen] = useState(false)
  const { mapPage } = useStoreLocatorMap(locale)
  const isDropdownOpenRef = useRef<HTMLDivElement>(null)
  const onClick = () => setIsDropDownOpen(!isDropdownOpen)
  const handleClickOutside = (event: MouseEvent) => {
    // @ts-expect-error TODO: 'EventTarget | null' is not assignable to parameter of type 'Node | null'.
    if (isDropdownOpenRef.current && !isDropdownOpenRef.current.contains(event.target)) {
      if (isDropdownOpen === true) setIsDropDownOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
  }, [isDropdownOpen])

  const [hasDocument, setHasDocument] = useState(false)
  useEffect(() => {
    if (typeof document !== 'undefined') {
      setHasDocument(true)
    }
  }, [])

  return (
    <Wrapper>
      <a href={`/${mapPage.slug}`}>
        <StoreLocatorIcon data-cy="iconsbar__storelocatoricon" src={pinIcon} alt="Store locator" />
      </a>
      <AccountDropdown
        role="button"
        tabIndex={0}
        onClick={onClick}
        onKeyPress={() => setIsDropDownOpen(!isDropdownOpen)}
        aria-label="Account Menu"
        ref={isDropdownOpenRef}
      >
        <AvatarIcon data-cy="iconsbar__avataricon" src={avatarIcon} alt="Account Menu" />
        <DropdownContent
          data-cy="iconsbar__signinlink"
          className={classNames({ dropdownOpen: isDropdownOpen })}
        >
          {hasDocument && <AccountOptions microcopy={microcopy} />}
        </DropdownContent>
      </AccountDropdown>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 92px;
  line-height: 18.4px;
  > a {
    margin: 12px 0;
  }
  ${above.tabletLarge`
   justify-content: space-between;
    width: 130px;
  `}

  ${above.desktop`
    margin-right: 16px;
    > a {
      margin: 20px 0;
    }
  `}
`

const StoreLocatorIcon = styled.img`
  width: 26px;
  height: 26px;
`

const AccountDropdown = styled.div`
  position: relative;
  display: none;
  margin-top: 12px;
  :focus {
    box-shadow: 0px 0px 8px 2px ${color.secondaryCore};
    outline: none;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    right: 0px;
    width: 50px;
    height: 20px;
  }

  ${above.tabletLarge`
    display: inline-block;
  `}

  ${above.desktop`
    margin-top: 20px;
  `}
`

const AvatarIcon = styled.img`
  margin-bottom: 20px;
  width: 26px;
  height: 26px;
`

interface DropdownContentProps {
  className?: string
}

const DropdownContent = styled.div<DropdownContentProps>`
  display: none;
  padding: 24px;
  position: absolute;
  top: 50px;
  right: 0px;
  height: auto;
  width: 140px;
  background-color: ${color.lightRed};
  :before {
    content: '';
    display: block;
    position: absolute;
    top: -13px;
    right: 0px;
    width: 0;
    height: 0;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid ${color.lightRed};
  }
  > a {
    display: block;
    ${costaTextBold};
    color: white;
  }
  > a:nth-child(2n) {
    padding-top: 14px;
  }
  &.dropdownOpen {
    z-index: 50;
    display: block;
  }
`
