import React from 'react'

import { usePageAssemblyPath } from '@cw-monorepo/contentful/src/hooks/usePageAssemblyPath'
import { Logo } from 'ui/src/Logo'
import type { IProps } from '.'
import type * as Contentful from 'cw-frontend/src/types/contentful/old'

export interface AltLogo extends IProps {
  alternativeLogo: Contentful.ContentfulLink
}

export function AltLogo(props: AltLogo) {
  const { ariaLabel, alternativeLogo, locale, onClick, onKeyDown } = props
  const slug = usePageAssemblyPath(alternativeLogo.internalLink?.slug ?? '', locale ?? '')

  return (
    <Logo
      link={{
        to: slug,
        onClick,
        onKeyDown,
        'aria-label': alternativeLogo.title ?? ariaLabel,
      }}
      image={alternativeLogo.assetLink?.gatsbyImageData}
      description={alternativeLogo.assetLink?.description ?? ''}
    />
  )
}
