import React from 'react'
import { Helmet } from 'react-helmet'

interface LoadGoogleTagProps {
  id?: string
  isManager?: boolean
}

export function LoadGoogleTag({ id, isManager = false }: LoadGoogleTagProps) {
  if (!id) return null

  const src = isManager
    ? `https://www.googletagmanager.com/gtm.js?id=${id}`
    : `https://www.googletagmanager.com/gtag/js?id=${id}`

  return (
    <>
      {/* @ts-expect-error Property 'children' is missing */}
      <Helmet>
        <script async src={src} className="optanon-category-C0002"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];

          function gtag() { window.dataLayer.push(arguments); }

          gtag('js', new Date());
          gtag('config', '${id}');
        `}</script>
      </Helmet>
    </>
  )
}
