import React from 'react'
import styled from 'styled-components'

import { above } from 'ui/src/styles/index'

type FullWidthImage = {
  file: {
    url: string
  }
  description: string
}

export type IProps = Partial<{
  fullWidthImageDesktop: FullWidthImage
  fullWidthImageMobile: FullWidthImage
  fullWidthImageTablet: FullWidthImage
  fullWidthImageLargeTablet: FullWidthImage
}>

function FullWidthImage({
  fullWidthImageDesktop,
  fullWidthImageMobile,
  fullWidthImageTablet,
  fullWidthImageLargeTablet,
}: IProps) {
  return (
    <picture>
      <source media="(max-width: 767px)" srcSet={fullWidthImageMobile?.file.url} />
      <source media="(max-width: 1023px)" srcSet={fullWidthImageTablet?.file.url} />
      <source media="(max-width: 1439px)" srcSet={fullWidthImageLargeTablet?.file.url} />
      <source media="(min-width: 1440px)" srcSet={fullWidthImageDesktop?.file.url} />
      <Img src={fullWidthImageDesktop?.file.url} alt={fullWidthImageDesktop?.description} />
    </picture>
  )
}

const Img = styled.img`
  width: 100%;

  ${above.desktopLarge`
    width: 1392px; 
  `}
`

export default FullWidthImage
