import { useStaticQuery, graphql } from 'gatsby'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

const useWebsiteSettings = (locale = defaultLocale) => {
  const { allContentfulWebsiteSettings } = useStaticQuery(graphql`
    query WebsiteSettingsQuery {
      ...AllContentfulWebsiteSettingsOldFragment
    }
  `)

  return allContentfulWebsiteSettings.nodes.find(element => element.node_locale.includes(locale))
}

export default useWebsiteSettings
