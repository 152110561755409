import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import isRtl from 'utils/src/rtl'
import { above, costaTextBold, color } from 'ui/src/styles/index'
import useWebsiteSettings from '../../../hooks/useWebsiteSettings'
import ContentfulLink, { type IProps as LinkProps } from '../../ContentfulLink/contentfulLink'
import LanguageSelector from '../../LanguageSelector/languageSelector'
import { AccountOptions } from 'cw-frontend/src/helpers/loginHelper'
import avatarIcon from 'cw-frontend/src/assets/icons/avatar.svg'
import type { Microcopy } from 'utils/src/microcopy'

type NavLink = LinkProps['link'] & {
  internalLink?: {
    parentAssembly?: {
      slug?: string
    }
  }
}

interface IProps {
  locale?: string
  location: Location
  isMenuOpen: boolean
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  microcopy: Microcopy
}

export default function MainNav({
  location,
  isMenuOpen,
  setIsMenuOpen,
  locale,
  microcopy,
}: IProps) {
  const { mainNavigation } = useWebsiteSettings(locale)

  const checkActiveState = (link: NavLink, path: string) => {
    const isJapanSite = process.env.GATSBY_BUDDY_PIPELINE === 'japan'

    if (link?.htmlTarget || isJapanSite) {
      return false
    }

    if (link?.internalLink && path.includes(encodeURIComponent(link.internalLink.slug ?? ''))) {
      return true
    }

    if (
      link?.internalLink?.parentAssembly &&
      path.includes(encodeURIComponent(link.internalLink?.parentAssembly?.slug ?? ''))
    ) {
      return true
    }

    if (link?.externalLink && path.includes(link.externalLink)) {
      return true
    }

    if (link?.externalLink && path.includes(`/${link.externalLink.split('/')[3]}/`)) {
      return true
    }

    return false
  }

  const [hasDocument, setHasDocument] = useState(false)
  useEffect(() => {
    if (typeof document !== 'undefined') {
      setHasDocument(true)
    }
  }, [])

  return (
    <StyledMainNav>
      <ul>
        {mainNavigation.link.map((navItem: NavLink) => (
          <Links
            key={navItem?.title}
            showActive={checkActiveState(navItem, location.pathname)}
            onClick={() => setIsMenuOpen(false)}
            onKeyPress={() => setIsMenuOpen(false)}
          >
            <ContentfulLink link={navItem} locale={locale} />
          </Links>
        ))}
        {process.env.GATSBY_BUDDY_PIPELINE !== 'uk' && (
          <LanguageSelectorContainer>
            <LanguageSelector location={location} pageLocale={locale} />
          </LanguageSelectorContainer>
        )}
      </ul>
      {process.env.GATSBY_BUDDY_PIPELINE === 'uk' && (
        <AccountManagement className={classNames({ menuOpen: isMenuOpen })}>
          <AvatarIcon src={avatarIcon} />
          {hasDocument && <AccountOptions microcopy={microcopy} />}
        </AccountManagement>
      )}
    </StyledMainNav>
  )
}

const LanguageSelectorContainer = styled.div`
  display: block;
  background-color: #bd0d43;
  margin: 80px 0;

  li {
    width: auto !important;
    margin: 0 !important;
  }

  ${above.tabletLarge`
    display: none;
  `}
`

interface StyledMainNavProps {
  locale?: string
}

const StyledMainNav = styled.nav<StyledMainNavProps>`
  display: none;
  ${above.tabletLarge`
    display: block;
  `}
  width: 100%;
  line-height: 1.15;
  ${({ locale }) => (isRtl(locale ?? '') ? 'margin-left: 24px;' : 'margin-right: 24px;')}
`

interface LinksProps {
  showActive: boolean
}

const Links = styled.li<LinksProps>`
  display: inline-block;
  color: white;
  margin: 0 16px;
  ${costaTextBold};
  font-size: 16px;

  ${above.desktop`
    font-size: 20px;
  `}
  > a {
    border-bottom: ${props => props.showActive && '2px solid white'};
  }
`

const AccountManagement = styled.div`
  background-color: ${color.lightRed};
  position: absolute;
  bottom: 74px;
  left: 0px;
  width: 100%;
  padding: 38px 0px;
  background: ${color.lightRed};
  display: none;
  &.menuOpen {
    display: block;
  }
  ${above.tabletLarge`
  display:none;
  `}
  > a {
    font-size: 25px;
    ${costaTextBold};
    color: ${color.white};
  }
  > a:nth-child(3n) {
    display: block;
    padding-left: 64px;
    padding-top: 15px;
  }
`

const AvatarIcon = styled.img`
  display: inline-block;
  ${above.tabletLarge`
    display:none;
  `}
  overflow: hidden;
  margin-left: 36px;
  margin-right: 11px;
  width: 17px;
  height: 21px;
`
