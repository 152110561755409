import React from 'react'
import styled from 'styled-components'

import richTextRenderer from 'utils/src/richTextRenderer'
import { above, color, costaText } from 'ui/src/styles/index'

function CookiePolicyTable({
  node_locale,
  cookieTableAriaLabel,
  cookieTableTitle,
  purposeTableTitle,
  cookies,
}) {
  return (
    <CookieTable>
      <table aria-label={cookieTableAriaLabel}>
        <thead>
          <tr>
            <th aria-label={cookieTableTitle}>{cookieTableTitle}</th>
            <th aria-label={purposeTableTitle}>{purposeTableTitle}</th>
          </tr>
        </thead>
        <tbody>
          {cookies.map((cookie, i) => (
            <tr key={cookie.Title + i}>
              <td>{richTextRenderer(cookie.Title, node_locale)}</td>
              <td>{richTextRenderer(cookie.explanation, node_locale)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </CookieTable>
  )
}

export default CookiePolicyTable

const CookieTable = styled.div`
  background-color: ${color.white};
  padding: 0 40px 40px;

  p {
    margin-top: 0;
  }

  table {
    background-color: ${color.offWhite};
    border-radius: 3px;
    padding: 40px 2.5%;
    font-size: 14px;
    line-height: 1.57;
    display: block;
    position: relative;
    overflow: hidden;

    ${above.tablet`
      padding: 40px 4% 40px;
      font-size: 16px;
      line-height: 1.5;
      border-collapse: collapse;
    `}

    ${above.desktop`
      padding: 40px 32px;
      max-width: 1034px;
      margin: 0 auto;
    `}
  }

  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  ${above.tablet`
    tr {
      display: flex;
    }
  `}

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    background-color: none;

    ${above.tablet`
      position: static;
      background-color: ${color.white};
    `}

    th {
      padding: 20px 24px;
      ${costaText};

      ${above.tablet`
        font-size: 18px;
        padding: 20px 3.5% 17px;
      `}
    }
  }

  tbody tr {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }

    ${above.tablet`
      margin-bottom: 0;
      &:nth-of-type(odd) {
        background-color: ${color.offWhite};
      }
      &:nth-of-type(even) {
        background-color: ${color.white};
      }
    `}
  }

  td,
  th {
    border: none;
    position: relative;
    padding: 24px 5% 24px 32%;
    text-align: left;
    word-wrap: break-word;

    ${above.tablet`
      padding: 24px 3.5%;
      backgound-color: none;
      &:nth-of-type(1) {
        width: 35%;
      }
      &:nth-of-type(2) {
        width: 64%;
      }
    `}

    ${above.tabletLarge`
      &:nth-of-type(1) {
        width: 25%;
      }
      &:nth-of-type(2) {
        width: 74%;
      }
    `}

    ${above.desktop`
      &:nth-of-type(1) {
        width: 26%;
      }
      &:nth-of-type(2) {
        width: 75%;
      }
    `}
  }

  td:before {
    ${costaText}
    position: absolute;
    top: 24px;
    left: 5%;
    padding-right: 4%;
    white-space: nowrap;

    ${above.tablet`
      display: none;
    `}
  }

  td:nth-of-type(1) {
    background-color: ${color.white};
    ${above.tablet`
        background-color: transparent;
      `}
    &:before {
      content: 'Cookie';
    }
  }

  td:nth-of-type(2) {
    background-color: ${color.offWhite};
    ${above.tablet`
        background-color: transparent;
      `}
    &:before {
      content: 'Purpose';
    }
  }

  a {
    ${costaText};
    color: ${color.costaRed};

    &:hover {
      color: ${color.lightRed};
    }
    word-break: break-all;
  }

  strong {
    ${costaText};
  }
`
