/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ description = '', lang = 'en', meta = [], title, graphImage = null, noindex }) {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          ...SiteMetaDataFragment
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: graphImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        { name: 'twitter:image', content: graphImage },
      ].concat(meta)}
    >
      {' '}
      {(process.env.GATSBY_DEPLOY_ENVIRONMENT !== 'production' || noindex) && (
        <meta name="robots" content="noindex" />
      )}
      {metaDescription && <meta name="description" content={metaDescription} />}
    </Helmet>
  )
}

export default SEO
