import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { above, costaTextBold, costaText, color } from 'ui/src/styles/index'
import useLocaleList from '../../hooks/useLocaleList'
import localeFormatter from 'utils/src/helpers/localeFormatter'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'
import extractLocaleFromUrl from '../../helpers/extractLocaleFromUrl/extractLocaleFromUrl'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

export default function LanguageSelector({ location, pageLocale }) {
  const locales = useLocaleList()
  const [active, setActive] = useState(false)
  const selecterRef = useRef(null)
  const { languageSelectorTitle } = useWebsiteSettings(pageLocale)

  const handleClickOutside = event => {
    if (selecterRef.current && !selecterRef.current.contains(event.target)) {
      if (active === true) setActive(!active)
    }
  }
  const onClick = () => setActive(!active)
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
  }, [active])

  const selectedItem = locales.find(
    element => localeFormatter(element) === extractLocaleFromUrl(location)
  )
  const selectedItemIndex = locales.findIndex(
    element => localeFormatter(element) === extractLocaleFromUrl(location)
  )
  if (selectedItem !== undefined) {
    locales.splice(selectedItemIndex, 1)
    locales.unshift(selectedItem)
  }

  return (
    locales.length > 1 && (
      <DropdownContainer data-cy="language-selector" ref={selecterRef} onClick={() => onClick()}>
        {languageSelectorTitle && (
          <Title data-cy="language-selector_title">{languageSelectorTitle}</Title>
        )}
        <LocalesContainer>
          {locales.map((locale, index) => {
            let formattedLink
            let path
            const formattedLocale = localeFormatter(locale)

            if (defaultLocale !== locale) {
              formattedLink = `/${formattedLocale}`
              path = location.pathname === '/' ? '' : `${location.pathname}${location.search}`
            } else {
              formattedLink = '/'
              path = `${location.pathname.substring(4)}${location.search}`
            }

            return (
              <ListItem
                data-cy="language-selector_item"
                key={locale}
                active={active}
                selected={index === 0}
                aria-label={formattedLocale}
                role="button"
              >
                <StyledLink
                  data-cy="language-selector_link"
                  to={`${formattedLink}${path}`}
                  onKeyDown={e => {
                    // disable enter key on the top item
                    if (e.key === 'Enter' && index === 0) {
                      e.preventDefault()
                      setActive(!active)
                    }
                  }}
                >
                  {formattedLocale.toUpperCase()}
                </StyledLink>
                <Arrow active={active} />
              </ListItem>
            )
          })}
        </LocalesContainer>
      </DropdownContainer>
    )
  )
}

const DropdownContainer = styled.div`
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  right: 0;
  padding: 12px 16px;
  z-index: 1;
  line-height: 1.15;

  ${above.desktop`
    padding: 0;
  `}
`

const LocalesContainer = styled.ul`
  display: flex;
  margin: 12px 0;
  width: 100%;
  justify-content: center;

  ${above.tabletLarge`
    display: block;
    flex-direction: column;
`};
`

const Title = styled.h3`
  ${costaTextBold};
  color: ${color.white};
  text-align: center;
  margin: 0;
  width: 100%;

  ${above.tabletLarge`
    display: none;
  `};
`

const ListItem = styled.li`
  color: ${color.white};
  margin: 0 16px;
  ${costaTextBold};
  font-size: 16px;
  align-items: center;
  width: 80px;
  text-align: center;
  width: auto;

  a {
    ${costaText};

    ${above.tabletLarge`
      font-size: 28px;
      min-width: 41px;
      ${costaTextBold};
  `}
  }

  ${above.tabletLarge`
    display: none;
    ${props =>
      props.active &&
      css`
        display: flex;
        background-color: ${color.lightRed};
      `}

  `}

  &:last-child {
    ${above.tabletLarge`
      position: absolute;
    `}

    span {
      visibility: hidden;
    }
  }

  &:first-child {
    display: flex;
  }

  ${props =>
    props.selected &&
    css`
      display: flex;
      cursor: default;
      a {
        pointer-events: none;
        ${costaTextBold};
      }
    `}

  ${above.desktop`
    font-size: 30px;
    margin: 0;
  `}
`

const StyledLink = styled(Link)`
  margin: 8px 8px;
`

const Arrow = styled.span`
  visibility: hidden;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #ffffff;
  position: relative;
  right: 0;
  transition: transform 0.3s;
  transform: rotate(180deg);
  margin-right: 12px;
  ${props =>
    props.active &&
    css`
      transform: rotate(0deg);
    `}
  ${above.tabletLarge`
    visibility: visible;
    display: inline;
  `}
`
