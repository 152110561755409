import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import richTextRenderer, { type RichTextBody } from 'utils/src/richTextRenderer'
import isRtl from 'utils/src/rtl'
import { above, color } from 'ui/src/styles/index'
import LinkCta from '../LinkCta/linkCta'
import { getBackgroundColor } from 'ui/src/Promo/utils'
import type { MaybeEmpty } from 'cw-frontend/src/types/utils'
import type * as Contentful from 'cw-frontend/src/types/contentful/old'

type IProps = MaybeEmpty<{
  imageAlignment: string
  image: Contentful.ContentfulMedia
  richText: RichTextBody
  linkCTA: Contentful.ContentfulLinkCta[]
  regionId: string
  bgColour: string
  nodeLocale: string
}>

function Teaser({
  imageAlignment = '',
  image,
  richText,
  linkCTA = [],
  regionId = '',
  bgColour,
  nodeLocale,
}: IProps) {
  const locale = nodeLocale ?? ''

  return (
    <Block
      imageAlignment={imageAlignment}
      id={regionId ?? undefined}
      bgColor={getBackgroundColor(bgColour)}
    >
      <ImageWrapper locale={locale} imageAlignment={imageAlignment}>
        {image?.gatsbyImageData && (
          <GatsbyImage
            style={{ height: '100%' }}
            image={image.gatsbyImageData}
            alt={image.description ?? ''}
          />
        )}
      </ImageWrapper>
      <Content locale={locale}>
        <RichText locale={locale}>{richTextRenderer(richText)}</RichText>
        {linkCTA && (
          <LinkCtaList>
            {linkCTA.map(link => (
              <li key={link.title}>
                <LinkCta
                  link={link.link}
                  customTitle={link.title}
                  ctaStyle={link.style || 'button'}
                  alignment={link.alignment || 'left'}
                />
              </li>
            ))}
          </LinkCtaList>
        )}
      </Content>
    </Block>
  )
}

interface BlockProps {
  imageAlignment: IProps['imageAlignment']
  bgColor: string
}

const Block = styled.article<BlockProps>`
  background-color: ${props => props.bgColor};
  margin: 0px 2.5%;
  padding: 40px 5% 40px;
  ul {
    padding: 0px;
  }

  ${above.mobile`
    min-height: auto;
  `}

  ${above.tablet<BlockProps>`
    max-width: 100%;
    margin: 0px 6%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${p => (p.imageAlignment === 'row-reverse' ? 'row' : 'row-reverse')}
  `}

  ${above.tabletLarge`
    margin: 0px 9%;
    flex-wrap: nowrap;
  `}

  ${above.desktop`
    padding: 40px 118px 0;
  `}

  ${above.desktopLarge`
    max-width: 1274px;
    margin: 0px 4%;
  `}
`

interface ImageWrapperProps {
  imageAlignment: IProps['imageAlignment']
  locale: string
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  width: auto;
  margin-bottom: 21px;
  img {
    margin-bottom: 0;
  }

  ${above.tablet`
    width: 100%;
    min-height: 200px;
  `}

  ${above.tabletLarge<ImageWrapperProps>`
    width: 70%;
    height: 100%;
    margin-bottom: 1rem;
    ${({ locale, imageAlignment }) =>
      isRtl(locale)
        ? `
          margin-left: ${imageAlignment === 'row-reverse' ? '2rem' : '0'};
          margin-right: ${imageAlignment === 'row-reverse' ? '0' : '2rem'};
        `
        : `
          margin-left: ${imageAlignment === 'row-reverse' ? '0' : '2rem'};
          margin-right: ${imageAlignment === 'row-reverse' ? '2rem' : '0'};
        `}
  `}

  ${above.desktop`
    width: 50%;
    height: auto;
    max-height: 220px;
  `}
`

interface ContentProps {
  locale: string
}

const Content = styled.div<ContentProps>`
  width: 100%;
  color: ${color.greyDarker};
  list-style: none;
  justify-content: left;
  display: flex;
  flex-direction: column;

  ${above.tablet`
    width: 100%;
  `}

  h1, h2, h3, h4, h5, h6 {
    color: ${color.costaRed};
    margin: 0 0 10px 0;
    width: 100%;
    text-align: ${({ locale }) => (isRtl(locale) ? 'right' : 'left')};
  }

  p {
    margin: 0;
    color: ${color.greyDarker};
    width: 100%;

    ${above.desktop`
      padding: 0;
    `};
  }
`

const LinkCtaList = styled.ul`
  margin: 18px 0 0 0;
`

interface RichTextProps {
  locale: string
}

const RichText = styled.div<RichTextProps>`
  display: flex;
  align-items: left;
  justify-content: left;
  flex-direction: column;
  margin: 0;
  padding: 0%;
  text-align: ${({ locale }) => (isRtl(locale) ? 'right' : 'left')};
`

export default Teaser
