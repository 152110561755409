import React from 'react'
import styled from 'styled-components'
import richTextRenderer from 'utils/src/richTextRenderer'
import { breakpoint, color, above } from 'ui/src/styles/index'
import LinkCTA from '../LinkCta/linkCta'

function BenefitsIcons({ title, benefits, callToAction, dataCy }) {
  return (
    <BenefitsIconsContainer>
      <Wrapper>
        {title && <Header data-cy={`${dataCy}__header`}>{title}</Header>}
        <BenefitList data-cy={`${dataCy}__benefiticons`}>
          {benefits &&
            benefits.map(benefit => (
              <Benefit key={benefit.id}>
                {benefit.icon && <Icon src={benefit.icon.file.url} alt="" />}
                {benefit.colouredText && <BenefitHeader>{benefit.colouredText}</BenefitHeader>}
                {benefit.regularText && (
                  <BenefitDescription>{richTextRenderer(benefit.regularText)}</BenefitDescription>
                )}
              </Benefit>
            ))}
        </BenefitList>
        {Object.entries(callToAction || {}).length !== 0 && (
          <LinkWrapper>
            <LinkCTA link={callToAction.link} alignment="center" />
          </LinkWrapper>
        )}
      </Wrapper>
    </BenefitsIconsContainer>
  )
}

const BenefitsIconsContainer = styled.article`
  max-width: ${breakpoint.desktop};
  margin: auto;
  padding: 72px 0;

  ${above.tablet`
    padding: 80px 0;
  `}
`

const Wrapper = styled.div`
  text-align: center;
  width: 79%;
  margin: auto;

  ${above.tablet`
    width: 100%;
  `}
`

const Header = styled.h3`
  color: ${color.costaRed};
  margin: 0 auto;
  text-align: center;

  ${above.tablet`
    width: 72%;
  `}

  ${above.tabletLarge`
    width: 64%;
  `}

  ${above.desktop`
    width: 684px;
  `}
`

const BenefitList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  ${above.tablet`
    flex-direction: row;
    margin: 56px 0 0 0;
  `};
`

const Benefit = styled.div`
  margin: 38px auto 0;

  ${above.tablet`
    flex: 0 27%;
    margin: 0 1.5%;

    :nth-child(n + 4) {
      margin: 64px 3% 0 3%;
    }

    :nth-child(5) {
      margin: 64px 3% 0 3%;
    }
  `}

  ${above.tabletLarge`
    flex: 0 23%;
    margin: 0 3% 0 3%;
  `}

  ${above.desktop`
    flex:0 316px
    margin: 0 3% 0 3%;
  `}
`

const Icon = styled.img`
  width: auto;
  height: 52px;
  margin-bottom: 28px;

  ${above.tablet`
    height: 80px;
    margin-bottom: 40px;
  `}
`

const BenefitHeader = styled.h6`
  color: ${color.burntOrange};

  margin: 0 0 28px 0;

  ${above.tablet`
    margin: 0 0 40px 0;
  `}
`

const BenefitDescription = styled.div`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  color: ${color.greyDarker};

  ${above.tablet`
    font-size: 18px;
    line-height: 1.56;
  `}
`

const LinkWrapper = styled.div`
  margin: 44px auto 0;

  ${above.tablet`
    margin: 64px auto 0;
  `}
`

export default BenefitsIcons
