import React from 'react'
import Teaser from './teaser'
import Standard from './standard'
import Campaign from './campaign'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'

/**
 * @description This is a wrapper for the Promo Component. It renders
 * the different Promo variations: Standard, Campaign and Teaser.
 */
function Promo({
  style = '',
  standardStyleBgColour = '',
  campaignStyleBgColour = '',
  campaignStyleTextColour = '',
  waveBorder,
  videoUrl,
  image = {},
  imageAlignment = '',
  seasonalSVG,
  richText = {},
  linkCTA = [],
  regionId = '',
  node_locale,
  fallbackImage,
}) {
  const align = imageAlignment === 'left' ? 'row-reverse' : 'initial'
  const { enableSeasonalSVGs } = useWebsiteSettings(node_locale)

  const displaySeasonalSVG = enableSeasonalSVGs && !!seasonalSVG?.file?.url
  switch (style) {
    case 'teaser':
      return (
        <Teaser
          image={image}
          imageAlignment={align}
          richText={richText}
          linkCTA={linkCTA}
          regionId={regionId}
          nodeLocale={node_locale}
          bgColour={standardStyleBgColour}
        />
      )
    case 'campaign':
      return (
        <Campaign
          imageAlignment={align}
          campaignStyleBgColour={campaignStyleBgColour}
          seasonalSVG={seasonalSVG}
          displaySeasonalSVG={displaySeasonalSVG}
          style={style}
          richText={richText}
          linkCTA={linkCTA}
          campaignStyleTextColour={campaignStyleTextColour}
          videoUrl={videoUrl}
          image={image}
          regionId={regionId}
          nodeLocale={node_locale}
          waveBorder={waveBorder}
          fallbackImage={fallbackImage}
        />
      )
    case 'standard':
      return (
        <Standard
          style={style}
          standardStyleBgColour={standardStyleBgColour}
          videoUrl={videoUrl}
          image={image}
          imageAlignment={align}
          displaySeasonalSVG={displaySeasonalSVG}
          seasonalSVG={seasonalSVG}
          richText={richText}
          linkCTA={linkCTA}
          regionId={regionId}
          fallbackImage={fallbackImage}
        />
      )
    default:
      return null
  }
}

export default Promo
