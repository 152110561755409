import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import classNames from 'classnames'

import isRtl from 'utils/src/rtl'
import { color, above } from 'ui/src/styles/index'
import Hamburger from './Hamburger/hamburger'
import { NavLogo } from './NavLogo'
import MainNav from './MainNav/mainNav'
import IconsBar from './IconsBar/iconsBar'
import InternationalIconsBar from '../International/IconsBar/iconsBar'
import useWebsiteSettings from '../../hooks/useWebsiteSettings'
import Hero from './Hero/hero'
import HeroWithTextBox from './Hero/heroWithTextBox'
import LanguageSelector from '../LanguageSelector/languageSelector'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'
import type * as Contentful from 'cw-frontend/src/types/contentful/old'

import waffle from 'cw-frontend/src/assets/backgrounds/waffle2.svg'

// TODO: remove hardcoded
const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE
const country = process.env.GATSBY_BUDDY_PIPELINE
const headerIcons = process.env.GATSBY_HEADER_ICONS || 'false'

interface IProps {
  hero?: {
    __typename?: string
  }
  location: Location
  locale?: string
  alternativeLogo?: Contentful.ContentfulLink
}

function Header({ alternativeLogo, hero, location, locale = defaultLocale }: IProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { mobileMenuAriaLabel, logoAriaLabel, logo } = useWebsiteSettings(locale)
  const microcopy = getMicrocopyObject(useMicrocopy(locale))
  const { __typename: heroTypeName, ...heroContent } = hero ?? {}

  return (
    <>
      <Wrapper
        data-cy="header-desktop"
        withHero={!!hero}
        locale={locale ?? ''}
        className={classNames({ menuOpen: isMenuOpen })}
      >
        <MaxWidth>
          <MobileMenu
            data-cy="header-mobile"
            aria-label={mobileMenuAriaLabel}
            role="button"
            tabIndex={0}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            onKeyPress={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Hamburger open={isMenuOpen} />
          </MobileMenu>
          <NavLogo
            logo={logo}
            alternativeLogo={alternativeLogo}
            locale={locale}
            ariaLabel={logoAriaLabel}
            headerIcons={headerIcons}
            onClick={() => setIsMenuOpen(false)}
            onKeyDown={() => setIsMenuOpen(false)}
          />
          <MainNav
            location={location}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            locale={locale}
            microcopy={microcopy}
          />
          {country !== 'uk' && (
            <LanguageSelectorContainer className={classNames({ menuOpen: isMenuOpen })}>
              <LanguageSelector location={location} pageLocale={locale} />
            </LanguageSelectorContainer>
          )}
          {country !== 'uk' && headerIcons === 'true' ? (
            <InternationalIconsBar locale={locale} />
          ) : (
            headerIcons === 'true' && <IconsBar locale={locale} microcopy={microcopy} />
          )}
        </MaxWidth>
      </Wrapper>
      {hero && heroTypeName === 'ContentfulHero' && <Hero locale={locale} content={heroContent} />}
      {hero && heroTypeName === 'ContentfulHeroWithTextBox' && (
        <HeroWithTextBox content={heroContent} />
      )}
    </>
  )
}

const LanguageSelectorContainer = styled.div`
  display: none;
  position: relative;
  right: 0;
  top: -8px;

  ${above.tabletLarge`
    display: block;
  `}

  ${above.desktop`
    top: 0;
  `}
`

interface OpenNavProps {
  locale: string
}

const openNav = css<OpenNavProps>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${color.costaRed} !important;
  background-image: url(${waffle}) !important;
  align-items: inherit;
  transform: translateX(0%);
  transition: transform 250ms ease-in-out;

  nav {
    display: block;
    position: fixed;
    left: 0;
    width: 100%;
    top: 74px;
    height: 100%;
    transform: translateX(0%);
    transition: transform 250ms ease-in-out;

    li {
      display: block;
      height: 38px;
      line-height: 38px;
      font-size: 25px;
      padding: 4px 4px;
      width: 247px;
      ${({ locale }) => (isRtl(locale) ? 'margin-right: 36px;' : 'margin-left: 36px;')}
      box-sizing: content-box;
    }
  }
`

interface WrapperProps {
  withHero?: boolean
  locale: string
}

const Wrapper = styled.div<WrapperProps>`
  margin: 0 auto;
  padding: 12px;
  height: 80px;
  z-index: 2;
  width: 100%;

  ${p =>
    (p.withHero &&
      css`
        position: absolute;
      `) ||
    css`
      background: ${color.costaRed};
    `}

  ${above.desktop`
    padding: 34px 0;
    height: 148px;
  `}

  &.menuOpen {
    ${openNav};
  }
`

const MaxWidth = styled.div`
  max-width: 1392px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  ${() =>
    headerIcons !== 'true' &&
    css`
      margin: 12px 0;

      ${above.desktop`
        margin: 0 auto;
      `}
    `};
`

const MobileMenu = styled.div`
  display: block;
  ${above.tabletLarge`
    display: none;
  `}
`

export default Header
