import React from 'react'
import styled from 'styled-components'
import { above, color, costaTextBold, costaText } from 'ui/src/styles'

import environmentsHelper from 'utils/src/environments'
import Header from '../Heading/heading'
import Input from '../../legacy/generic/Input/input'
import TextArea from '../../legacy/generic/TextArea/textArea'
import ButtonCTA from '../../legacy/generic/ButtonCTA/buttonCTA'
import PopupCTA from '../../legacy/generic/PopupCTA/popupCTA'
import validation from '../../helpers/legacy/validation'
import businessContactApi from '../../helpers/businessContactApi'

export default class BusinessContactUs extends React.Component {
  state = {
    inputs: {
      nameTitle: {},
      firstName: {},
      surname: {},
      contactNumber: {},
      email: {},
      addressLn1: {},
      addressLn2: {
        optional: true,
      },
      addressLn3: {
        optional: true,
      },
      addressLn4: {
        optional: true,
      },
      addressLn5: {
        optional: true,
      },
      addressLn6: {
        optional: true,
      },
      companyName: {},
      ccepCustomerNumber: {
        optional: true,
      },
      enquiryOptions: {},
      description: {
        optional: true,
      },
    },
    loadingSpinner: false,
    showErrorPopup: false,
    showSuccessPopup: false,
  }

  updateElementsInState = input => {
    const { inputs } = this.state

    inputs[input.name].empty = !inputs[input.name].value
    inputs[input.name].type = input.type
    inputs[input.name].value = input.value

    this.setState({ inputs })
  }

  submitForm = () => {
    const environment = environmentsHelper.getEnvironment()
    const { inputs } = this.state

    this.validateForm().then(valid => {
      if (valid) {
        this.setState({
          loadingSpinner: true,
        })

        const body = {
          name: `${inputs.nameTitle.value} ${inputs.firstName.value} ${inputs.surname.value}`,
          telephone: inputs.contactNumber.value,
          email: inputs.email.value,
          address: {
            city: inputs.addressLn4.value || '',
            county: inputs.addressLn5.value || '',
            lines: [
              inputs.addressLn1.value || '',
              inputs.addressLn2.value || '',
              inputs.addressLn3.value || '',
            ],
            postcode: inputs.addressLn6.value || '',
          },
          enquiryOptions: inputs.enquiryOptions.value,
          companyName: inputs.companyName.value,
          ccepCustomerNumber: inputs.ccepCustomerNumber.value || '',
          message: inputs.description.value || '',
        }

        businessContactApi
          .sendEmail(environment, 'contact', body)
          .then(response => {
            if ((response && response.error) || !response.status) {
              this.setState({
                loadingSpinner: false,
                showErrorPopup: true,
              })
            } else {
              this.setState({
                loadingSpinner: false,
                showSuccessPopup: true,
              })

              window._satellite && window._satellite.track('business-contact-submission')
            }
          })
          .catch(() => {
            this.setState({
              loadingSpinner: false,
              showErrorPopup: true,
            })
          })
      } else {
        this.setState({
          loadingSpinner: false,
        })
      }
    })
  }

  validateForm = () =>
    new Promise(resolve => {
      const inputs = { ...this.state.inputs }
      const { nameTitlePlaceholder, enquiryOptionsPlaceholder } = this.props
      const inputIndexes = Object.keys(inputs)
      const titleRegex = new RegExp(nameTitlePlaceholder)
      const enquiryOptionsRegex = new RegExp(enquiryOptionsPlaceholder)
      let valid = true

      inputIndexes.forEach(el => {
        if (
          (inputs[el].optional !== true &&
            (!inputs[el].value ||
              !inputs[el].value.match(validation[el]) ||
              inputs[el].value.match(titleRegex) ||
              inputs[el].value.match(enquiryOptionsRegex) ||
              inputs[el].value.match(/^Please select/))) ||
          (['firstName', 'surname'].includes(el) &&
            (inputs[el].value.match(validation.containsUrl) ||
              inputs[el].value.match(validation.longEmail)))
        ) {
          inputs[el].valid = false
          inputs[el].empty = !inputs[el].value
          valid = false

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        } else {
          inputs[el].valid = true
        }
      })
      this.setState({ inputs }, () => resolve(valid))
    })

  updateComment = value => {
    const inputs = { ...this.state.inputs }

    inputs.description.value = value
    this.setState({ inputs })
  }

  togglePopup = open => {
    this.setState({
      showErrorPopup: open,
      showSuccessPopup: open,
    })
  }

  render() {
    const {
      pageTitle,
      subtitle,
      nameTitlePlaceholder,
      nameTitleOptions,
      firstNamePlaceholder,
      surnamePlaceholder,
      nameTitle,
      nameDescription,
      enquiryOptionsPlaceholder,
      enquiryOptions,
      enquiryTitle,
      enquiryDescription,
      companyNameTitle,
      companyNameDescription,
      contactNumberTitle,
      contactNumberDescription,
      emailTitle,
      emailDescription,
      ccepCustomerNumberTitle,
      ccepCustomerNumberDescription,
      descriptionTitle,
      description,
      buttonText,
      enquiryOptionValidationText,
      nameValidationText,
      companyNameValidationText,
      contactNumberValidationText,
      emailValidationText,
      ccepCustomerNumberValidationText,
      companyAddressDescription,
      companyAddressTitle,
      companyAddressValidationText,
      addressLine1Placeholder,
      addressLine2Placeholder,
      addressLine3Placeholder,
      addressLine4Placeholder,
      addressLine5Placeholder,
      addressLine6Placeholder,
      successBackButtonText,
      successBackButtonLink,
      successPopupHeader,
      successPopupBody,
      successButtonText,
      successButtonLink,
      errorButtonText,
      errorPopupHeader,
      errorPopupBody,
    } = this.props

    const { inputs, loadingSpinner, showErrorPopup, showSuccessPopup } = this.state
    return (
      <Wrapper>
        <StyledBusinessContactUs data-cy="germany-business-contact-form">
          <Header dataCy="business-contact-form" text={pageTitle} subtitle={subtitle} />
          <form className="form">
            <Input
              inputs={[
                {
                  placeholder: enquiryOptionsPlaceholder,
                  type: 'select',
                  name: 'enquiryOptions',
                  itemList: enquiryOptions,
                  valid: inputs.enquiryOptions.valid,
                  validation: this.updateElementsInState,
                  selectedIndex: 0,
                },
              ]}
              dataCy="enquiryoptions"
              heading={enquiryTitle}
              description={enquiryDescription}
              items={enquiryOptions}
              customStyles="sector input"
              error={inputs.enquiryOptions.valid === false}
              errorMessage={enquiryOptionValidationText}
            />
            <Input
              inputs={[
                {
                  placeholder: nameTitlePlaceholder,
                  type: 'select',
                  itemList: nameTitleOptions,
                  name: 'nameTitle',
                  valid: inputs.nameTitle.valid,
                  validation: this.updateElementsInState,
                  selectedIndex: 0,
                },
                {
                  placeholder: firstNamePlaceholder,
                  type: 'text',
                  name: 'firstName',
                  valid: inputs.firstName.valid,
                  validation: this.updateElementsInState,
                },
                {
                  placeholder: surnamePlaceholder,
                  type: 'text',
                  name: 'surname',
                  valid: inputs.surname.valid,
                  validation: this.updateElementsInState,
                },
              ]}
              dataCy="name"
              heading={nameTitle}
              description={nameDescription}
              customStyles="nameField input"
              error={
                inputs.firstName.valid === false ||
                inputs.surname.valid === false ||
                inputs.nameTitle.valid === false
              }
              errorMessage={nameValidationText}
            />
            <Input
              inputs={[
                {
                  type: 'text',
                  name: 'companyName',
                  valid: inputs.companyName.valid,
                  validation: this.updateElementsInState,
                },
              ]}
              dataCy="companyname"
              heading={companyNameTitle}
              description={companyNameDescription}
              error={inputs.companyName.valid === false}
              errorMessage={companyNameValidationText}
              customStyles="input"
            />
            <Input
              inputs={[
                {
                  type: 'tel',
                  name: 'contactNumber',
                  valid: inputs.contactNumber.valid,
                  validation: this.updateElementsInState,
                },
              ]}
              dataCy="contactnumber"
              heading={contactNumberTitle}
              description={contactNumberDescription}
              error={inputs.contactNumber.valid === false}
              errorMessage={contactNumberValidationText}
              customStyles="input"
            />
            <Input
              inputs={[
                {
                  type: 'text',
                  name: 'email',
                  valid: inputs.email.valid,
                  validation: this.updateElementsInState,
                },
              ]}
              dataCy="email"
              heading={emailTitle}
              description={emailDescription}
              error={inputs.email.valid === false}
              errorMessage={emailValidationText}
              customStyles="input"
            />
            <Input
              dataCy="companyaddress"
              customStyles="input"
              heading={companyAddressTitle}
              description={companyAddressDescription}
              error={
                inputs.addressLn1.valid === false ||
                inputs.addressLn4.valid === false ||
                inputs.addressLn6.valid === false
              }
              errorMessage={companyAddressValidationText}
              inputs={[
                {
                  placeholder: addressLine1Placeholder,
                  type: 'text',
                  name: 'addressLn1',
                  optional: inputs.addressLn1.optional,
                  valid: inputs.addressLn1.valid,
                  validation: this.updateElementsInState,
                  onFocusMethod: this.inputsAnalyticsCall,
                },
                {
                  placeholder: addressLine2Placeholder,
                  type: 'text',
                  name: 'addressLn2',
                  optional: inputs.addressLn2.optional,
                  valid: inputs.addressLn2.valid,
                  validation: this.updateElementsInState,
                  onFocusMethod: this.inputsAnalyticsCall,
                },
                {
                  placeholder: addressLine3Placeholder,
                  type: 'text',
                  name: 'addressLn3',
                  optional: inputs.addressLn3.optional,
                  valid: inputs.addressLn3.valid,
                  validation: this.updateElementsInState,
                  onFocusMethod: this.inputsAnalyticsCall,
                },
                {
                  placeholder: addressLine4Placeholder,
                  type: 'text',
                  name: 'addressLn4',
                  optional: inputs.addressLn4.optional,
                  valid: inputs.addressLn4.valid,
                  validation: this.updateElementsInState,
                  onFocusMethod: this.inputsAnalyticsCall,
                },
                {
                  placeholder: addressLine5Placeholder,
                  type: 'text',
                  name: 'addressLn5',
                  optional: inputs.addressLn5.optional,
                  valid: inputs.addressLn5.valid,
                  validation: this.updateElementsInState,
                  onFocusMethod: this.inputsAnalyticsCall,
                },
                {
                  placeholder: addressLine6Placeholder,
                  type: 'text',
                  name: 'addressLn6',
                  optional: inputs.addressLn6.optional,
                  valid: inputs.addressLn6.valid,
                  validation: this.updateElementsInState,
                  onFocusMethod: this.inputsAnalyticsCall,
                },
              ]}
            />
            <Input
              inputs={[
                {
                  type: 'text',
                  name: 'ccepCustomerNumber',
                  optional: inputs.ccepCustomerNumber.optional,
                  valid: inputs.ccepCustomerNumber.valid,
                  validation: this.updateElementsInState,
                },
              ]}
              dataCy="customernumber"
              heading={ccepCustomerNumberTitle}
              description={ccepCustomerNumberDescription}
              error={inputs.ccepCustomerNumber.valid === false}
              errorMessage={ccepCustomerNumberValidationText}
              customStyles="input"
            />
            <TextArea
              dataCy="description"
              heading={descriptionTitle}
              description={description}
              onChange={this.updateComment}
              maxLength={1000}
            />
            <ButtonCTA
              dataCy="submit"
              value={buttonText}
              icon
              customStyles="button"
              onClick={this.submitForm}
              loading={loadingSpinner}
            />
            <PopupCTA
              buttonValue={successBackButtonText}
              click={() => (window.location = successBackButtonLink)}
              icon
              content={
                <div>
                  <header>
                    <h1>{successPopupHeader}</h1>
                  </header>
                  <p>{successPopupBody}</p>
                  <ButtonCTA
                    value={successButtonText}
                    icon
                    link={successButtonLink}
                    customStyles="successButton"
                    dataCy="popupsuccess"
                  />
                </div>
              }
              visible={showSuccessPopup}
              type="link"
              customStyles="popupHeader"
              dataCy="backtohome"
            />
            <PopupCTA
              buttonValue={errorButtonText}
              click={() => this.togglePopup(false)}
              icon
              content={
                <div>
                  <header>
                    <h1>{errorPopupHeader}</h1>
                  </header>
                  <p>{errorPopupBody}</p>
                </div>
              }
              visible={showErrorPopup}
              customStyles="popupHeader"
              dataCy="popuperror"
            />
          </form>
        </StyledBusinessContactUs>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  background-color: ${color.white};
  padding: 28px 16px;
  margin: 0 auto;

  ${above.tablet`
    padding: 56px 42px;
    max-width: 768px;
  `}

  ${above.tabletLarge`
    padding: 66px 84px;
    max-width: 824px;
  `}

  ${above.desktop`
    padding: 66px 118px;
    max-width: 1274px;
  `}
`

const StyledBusinessContactUs = styled.section`
  padding-bottom: 35px;
  margin: 0 8px;

  .hidden {
    display: none;
  }

  .form {
    margin-top: 31px;

    ${above.tablet`
      max-width: 474px;
      margin: 39px auto 0;
    `}

    ${above.tabletLarge`
      margin-top: 55px;
      margin-left: 0;
    `}

    ${above.desktop`
      max-width: 448px;
      margin-top: 57px;
      margin-left: 128px;
    `}

    input {
      width: 100%;
    }

    .fullWidth {
      box-sizing: content-box;
    }

    .input {
      margin-bottom: 8px;

      ${above.tablet`
        margin-bottom: 28px;
      `}

      div {
        div {
          input {
            display: block;
          }
        }
      }
    }

    .nameField {
      display: inline-block;
      vertical-align: top;

      > div > div:nth-of-type(1) {
        width: 40%;
        vertical-align: top;

        > section {
          width: 100%;
          padding: 0;
        }
      }

      > div > div:nth-of-type(2) {
        width: 60%;
        display: inline-block;
      }

      > div > div:nth-of-type(3) {
        width: 100%;
      }
    }

    .addressFinder {
      > section:nth-of-type(1) {
        div input {
          padding: 0 20px;
        }
      }
    }

    .disclaimer {
      font-style: italic;
      padding: 0;
      margin-top: 16px;

      ${above.tablet`
        margin-top: 46px;
      `}

      ${above.tabletLarge`
        width: 684px;
      `}
    }

    .popupContainer {
      button {
        margin: 0 auto;
      }
    }

    .button {
      margin-top: 44px;

      ${above.tablet`
        margin-left: 0;
      `}
    }

    .successButton {
      margin-bottom: 20px;
    }

    .sector {
      > div > div {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .customLink:nth-of-type(1) {
      margin: 0 0 16px;
      padding: 0;
      text-decoration: underline;
      ${costaTextBold};
    }

    .addressFinder {
      width: 100%;
      top: 0;

      ${above.tablet`
        margin-top: 34px;
      `}

      > section:nth-of-type(1) {
        width: 96px;

        ${above.tablet`
          width: 240px;
        `}

        ${above.desktop`
          width: 208px;
        `}

        input {
          width: 100%;
        }
      }

      > button:nth-of-type(1) {
        width: auto;
        margin: 0 0 0 16px;
        padding: 6px;
        height: 48px;
        float: right;
        top: 0;

        ${above.tablet`
          margin: 0;
          position: static !important;
          width: 174px;
          height: 56px;
          letter-spacing: normal;
        `}
      }

      > button:nth-of-type(2) {
        ${costaText};
        letter-spacing: normal;
        margin-top: -8px;
        text-decoration: underline;
      }
    }
  }

  .header {
    margin: 0 auto !important;
    font-size: 30px;
    line-height: 30px;

    ${above.tablet`
      font-size: 50px;
      line-height: 50px;
      max-width: 426px;
      margin: 0 auto 17px !important;
    `}

    ${above.tabletLarge`
      font-size: 50px;
      max-width: 572px;
    `}

    ${above.desktop`
      max-width: 684px;
    `}

    + p {
      ${above.tablet`
        max-width: 426px;
        margin: auto;
      `}

      ${above.tabletLarge`
        max-width: 572px;
      `}

      ${above.desktop`
        max-width: 684px;
      `}
    }
  }

  .popupHeader {
    div {
      div {
        div {
          header {
            h1 {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
`
