import React, { useCallback } from 'react'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import type { Microcopy } from 'utils/src/microcopy'

const Link = styled('a')`
  cursor: pointer;
`

interface AccountOptionsB2CProps {
  microcopy: Microcopy
}

export const AccountOptions = (props: AccountOptionsB2CProps) => {
  const { microcopy } = props
  const { instance } = useMsal()

  const handleLogoutRedirect = useCallback(() => {
    instance.logoutRedirect().catch(error => console.log(error))
  }, [instance])

  return (
    <>
      <AuthenticatedTemplate>
        <a href={withPrefix('/costa-club/account-home#/history')}>
          {microcopy.account.myAccountLabel}
        </a>
        <Link onClick={handleLogoutRedirect}>{microcopy.account.logoutLabel}</Link>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <a href={withPrefix('/costa-club/login')}>{microcopy.account.loginLabel}</a>
      </UnauthenticatedTemplate>
    </>
  )
}
